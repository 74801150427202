define(['./util.js', './extend.js', './config-evaluator.js'],
    function(util, extend, ConfigEvaluator) {

        var _errorsOnlyPredicate = function _errorsOnlyPredicate(result) {
            return result.returnValue === false;
        };

        function ValidationState(instance) {
            this._instance = instance;
            this._configEvaluator = new ConfigEvaluator(this._instance.getModulePath(), this._instance.instanceRegistry);
            this.reset();
        }

        ValidationState.prototype.reset = function reset() {
            this._state = {};
            this._children = {};
            this._calculcatedMergedState = {};
        };

        ValidationState.prototype.getState = function getState() {
            return this._state;
        };

        ValidationState.prototype.setState = function setState(state) {
            if (util.isEqual(this.getState(), state)) {
                return;
            }

            this._state = state || {};
            this.triggerUpdated();
        };

        ValidationState.prototype.triggerUpdated = function triggerUpdated() {
            if (this._instance.hasParent()) {
                // todo(slind): could refactor this so that ComponentInstance gets hooked up with its child instances (rather than handling that logic in here)
                this._instance.getRelative('_').getValidationState().setChild(this._instance.getOwnInstanceId(), this);
            }

            this._calculateMergedState();
            // (isValid, ownState, fullMergedState)
            this._instance.trigger('validationStateChanged', [this.isValid(), this.getState(), this.getMergedStateFiltered()]);
            if (this._instance.hasParent()) {
                this._instance.getRelative('_').getValidationState().triggerUpdated();
            }
        };

        ValidationState.prototype.getChildren = function getChildren() {
            return this._children;
        };

        ValidationState.prototype.setChild = function setChild(childName, childValidationState) {
            this._children[childName] = childValidationState;
        };

        ValidationState.prototype.getNamespacedKey = function getNamespacedKey(key) {
            return [this._instance.getModulePath(), key].join('_');
        };

        ValidationState.prototype._calculateMergedState = function _calculateMergedState() {
            var results = this.getNamespacedState();
            util.each(this._children, function(child) {
                util.each(child.getMergedStateFiltered(), function(result, key) {
                    results[key] = result;
                });
            });

            this._calculcatedMergedState = results;
        };

        ValidationState.prototype.getMergedStateFiltered = function getMergedStateFiltered(predicate) {
            var results = extend({}, this._calculcatedMergedState);
            if (!predicate) {
                return results;
            }

            util.each(results, function(rule, key) {
                if (!predicate(rule)) {
                    delete results[key];
                }
            });

            return results;
        };

        ValidationState.prototype.getNamespacedState = function getNamespacedState() {
            var self = this;
            var results = {};
            util.each(this.getState(), function(result, key) {
                result = extend(true, {}, result);
                var evaluationResult = self._configEvaluator.evaluate(self._instance.getLabels()[result.rule.labelKey] || result.rule.labelKey, null, {selfStatic: result.rule});
                result.label = evaluationResult.evaluated;
                result.ownInstanceId = self._instance.getOwnInstanceId();
                result.modulePath = self._instance.getModulePath();
                results[self.getNamespacedKey(key)] = result;
            });

            return results;
        };

        ValidationState.prototype.isValid = function isValid() {
            var mergedState = this.getMergedStateFiltered(_errorsOnlyPredicate);
            return Object.keys(mergedState).length === 0;
        };

        return ValidationState;
    });
