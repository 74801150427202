define(['./each.js'], function(each) {
    'use strict';

    var filter = function(obj, predicate) {
        var results = [];
        each(obj, function(value, index, list) {
            if (predicate(value, index, list)) {
                results.push(value);
            }
        });

        return results;
    };

    return filter;
});
