define([], function() {
    'use strict';
    var validation = require('./validation');

    require('./rules/required');
    require('./rules/range');
    require('./rules/integer');
    require('./rules/regex');
    require('./rules/alpha');
    require('./rules/equality');

    return validation;
});
