define(['./is-object.js'], function(isObject) {
    'use strict';

    return {
        deep: deepFreeze,
        deepProperties: deepFreezeProperties
    };

    function deepFreezeProperties(obj) {
        for (var prop in obj) {
            if (!obj.hasOwnProperty(prop)) {
                continue;
            }

            if (isObject(obj[prop])) {
                deepFreeze(obj[prop]);
            }
        }

        return obj;
    }

    function deepFreeze(obj) {
        // assumes that obj has been frozen (recursively) prior to this if it is already frozen at the top level
        // this works for our use cases in the config module, but wouldn't make sense in other cases
        if (Object.isFrozen(obj)) {
            return obj;
        }

        for (var prop in obj) {
            if (!obj.hasOwnProperty(prop)) {
                continue;
            }

            if (isObject(obj[prop])) {
                deepFreeze(obj[prop]);
            }
        }

        return Object.freeze(obj);
    }
});
