define([], function() {
    'use strict';
    /**
     * Determines if a given object is a DOM element.
     * @function module:morningstar.asterix.util/isDomElement
     * @param  {any}  any type
     * @return {Boolean} true if DOM element, false if not
     */
    var isDomElement = function(elm) {

        return elm instanceof HTMLElement;
    };

    return isDomElement;
});
