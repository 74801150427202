define([], /** @lends morningstar.asterix.util */ function() {
    'use strict';
    /**
     * Determines if a given object is an Array.
     * @function module:morningstar.asterix.util/isArray
     * @param  {any}  object any type of object
     * @return {Boolean} true if array, false if not
     */
    var isArray = Array.isArray || function(object) {
            return object instanceof Array;
            //return typeof object === 'array';
        };

    return isArray;
});
