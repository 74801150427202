define(['./get-prototype.js',
    './is-window.js'
], /** @lends morningstar.asterix.util */ function(getPrototype, isWindow) {
    'use strict';
    /**
     * Determines if a given object is a plain object.
     * @function module:morningstar.asterix.util/isPlainObject
     * @param  {any}  object any type of object
     * @return {Boolean} true if plain object, false if not
     */
    var isPlainObject = function(object) {
        if (!object || object.nodeType) {
            return false;
        }

        return typeof object === 'object' && getPrototype(object) === Object.prototype;
    };

    return isPlainObject;
});
