define([], function() {
    'use strict';

    // This overrides the d3.selection.prototype.classed object if needed
    // because the core-js es.string.split.js module (used in IE11) does not work with
    // the regex used in the d3_selection_classes function

    function classedUpdated(name, value) {
        if (arguments.length < 2) {

            // For classed(string), return true only if the first node has the specified
            // class or classes. Note that even if the browser supports DOMTokenList, it
            // probably doesn't support it on SVG elements (which can be animated).
            if (typeof name === "string") {
                var node = this.node(),
                    n = (name = d3_selection_classes(name)).length,
                    i = -1;
                if (value = node.classList) {
                    while (++i < n)
                        if (!value.contains(name[i])) return false;
                } else {
                    value = node.getAttribute("class");
                    while (++i < n)
                        if (!d3_selection_classedRe(name[i]).test(value)) return false;
                }
                return true;
            }

            // For classed(object), the object specifies the names of classes to add or
            // remove. The values may be functions that are evaluated for each element.
            for (value in name) this.each(d3_selection_classed(value, name[value]));
            return this;
        }

        // Otherwise, both a name and a value are specified, and are handled as below.
        return this.each(d3_selection_classed(name, value));
    };

    function d3_selection_classedRe(name) {
        return new RegExp("(?:^|\\s+)" + d3.requote(name) + "(?:\\s+|$)", "g");
    }

    function d3_selection_classes(name) {
        // Updated from /^|\s+/
        return (name + "").trim().split(/^\s+/);
    }

    // Multiple class names are allowed (e.g., "foo bar").
    function d3_selection_classed(name, value) {
        name = d3_selection_classes(name).map(d3_selection_classedName);
        var n = name.length;

        function classedConstant() {
            var i = -1;
            while (++i < n) name[i](this, value);
        }

        // When the value is a function, the function is still evaluated only once per
        // element even if there are multiple class names.
        function classedFunction() {
            var i = -1,
                x = value.apply(this, arguments);
            while (++i < n) name[i](this, x);
        }

        return typeof value === "function" ?
            classedFunction :
            classedConstant;
    }

    function d3_selection_classedName(name) {
        var re = d3_selection_classedRe(name);
        return function(node, value) {
            if (c = node.classList) return value ? c.add(name) : c.remove(name);
            var c = node.getAttribute("class") || "";
            if (value) {
                re.lastIndex = 0;
                if (!re.test(c)) node.setAttribute("class", d3_collapse(c + " " + name));
            } else {
                node.setAttribute("class", d3_collapse(c.replace(re, " ")));
            }
        };
    }

    function d3_collapse(s) {
        return s.trim().replace(/\s+/g, " ");
    }

    function shouldOverrideClassedFunction(d3) {
        var shouldOverride = false;
        // Check we need to override the classed function (i.e. the split function is buggy)
        var testSplitter = ('aaa').split(/^|\s+/);
        var splitBug = testSplitter.length === 3;
        // Check we have the classed function to override
        if (splitBug && d3 && d3.selection && d3.selection.prototype.classed) {
            shouldOverride = true;
        }
        return shouldOverride;
    }

    function fixSplitForD3(d3) {
        if (shouldOverrideClassedFunction(d3)) {
            d3.selection.prototype.classed = classedUpdated;
        }
    }
    return fixSplitForD3;
});
