define([], /** @lends morningstar.asterix.util */ function() {
    'use strict';
    /**
     * Retrieves an object's prototype
     * @function module:morningstar.asterix.util/getPrototype
     * @param {any} obj object any type of object
     * @return {Boolean} true if function, false if not
     */
    var getPrototype = function(obj) {
        return obj.__proto__ || obj.prototype || (obj.constructor && obj.constructor.prototype) || Object.prototype;
    };

    return getPrototype;
});
