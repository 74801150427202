define(['../util/is-string.js'], function(isString) {
    function FilterLogProvider(filterOptions, logProvider) {
        if (!logProvider) {
            throw 'logProvider is undefined/null';
        }
        this.logLevel = filterOptions.logLevel;
        this._componentModulePath = getRegExForString(filterOptions.componentModulePath);
        this._operation = getRegExForString(filterOptions.operation);
        this._includeStack = filterOptions.includeStack;
        this._filterFunc = filterOptions.filterFunc;
        this._logProvider = logProvider;
    }

    function getRegExForString(pattern) {
        return isString(pattern) ? new RegExp(pattern) : pattern;
    }

    FilterLogProvider.prototype._allow = function(args) {
        var operation = args[0];
        var tags = args[args.length - 1] && args[args.length - 1].tags ? args[args.length - 1].tags : args[args.length - 1] || {};
        return (!this._componentModulePath || (tags.componentModulePath && tags.componentModulePath.match && tags.componentModulePath.match(this._componentModulePath) !== null)) &&
            (!this._componentType || (tags.componentType && tags.componentType.match && tags.componentType.match(this._componentType) !== null)) &&
            (!this._operation || (operation && operation.match && operation.match(this._operation) !== null)) &&
            (!this._filterFunc || this._filterFunc.apply(this, args));
    };

    FilterLogProvider.prototype.setLogLevel = function(logLevel) {
        this.logLevel = logLevel;
    };

    FilterLogProvider.prototype.fatal = function() {
        var self = this;
        if (this._allow(arguments)) {
            this._logProvider.fatal.apply(self._logProvider, this._includeStack === true ? Array.apply(null, arguments).concat(new Error().stack) : arguments);
        }
    };

    FilterLogProvider.prototype.error = function() {
        var self = this;
        if (this._allow(arguments)) {
            this._logProvider.error.apply(self._logProvider, this._includeStack === true ? Array.apply(null, arguments).concat(new Error().stack) : arguments);
        }
    };

    FilterLogProvider.prototype.warn = function() {
        var self = this;
        if (this._allow(arguments)) {
            this._logProvider.warn.apply(self._logProvider, this._includeStack === true ? Array.apply(null, arguments).concat(new Error().stack) : arguments);
        }
    };

    FilterLogProvider.prototype.info = function() {
        var self = this;
        if (this._allow(arguments)) {
            this._logProvider.info.apply(self._logProvider, this._includeStack === true ? Array.apply(null, arguments).concat(new Error().stack) : arguments);
        }
    };

    FilterLogProvider.prototype.debug = function() {
        var self = this;
        if (this._allow(arguments)) {
            this._logProvider.debug.apply(self._logProvider, this._includeStack === true ? Array.apply(null, arguments).concat(new Error().stack) : arguments);
        }
    };

    //console.trace in IE, Firefox doesn't print arguments, instead they print only the stacktrace
    //which is not the purpose of this trace. This is meant for performance-tracing
    FilterLogProvider.prototype.trace = function() {
        var self = this;
        if (this._allow(arguments)) {
            this._logProvider.trace.apply(self._logProvider, this._includeStack === true ? Array.apply(null, arguments).concat(new Error().stack) : arguments);
        }
    };

    return FilterLogProvider;
});
