define(['./util.js', './extend.js', './instrumentation'], function(util, extend, instrumentation) {
    'use strict';

    var singleMustacheRegex = new RegExp('\\{\\{\\_\\s*(.*?)\\s*\\_\\}\\}');
    var multiMustacheRegex = new RegExp('\\{\\{\\_\\s*(.*?)\\s*\\_\\}\\}', 'g');
    var exactlyOneMustacheRegex = new RegExp('^\\{\\{\\_\\s*([^}]*?)\\s*\\_\\}\\}$');
    var baseRegex = '\\((.*?)\\)(?:\\.([^+\\-*\\/\\s|()]*))?(?:\\|(number|date)(?:\\(([^)]*)\\))?)?';

    function ConfigEvaluator(modulePath, instanceRegistry, context) {
        this._logger = instrumentation.getTagsDecoratedLogger({
            componentModulePath: modulePath
        });
        this._logger.debug('ConfigEvaluator constructor', instanceRegistry, context);
        this.modulePath = modulePath;
        this.instanceRegistry = instanceRegistry;
        this.context = context;
        this._cache = {};
    }

    /**
     * Evaluates mustache expressions in the given object.
     * @param  {object} obj - The object to be evaluated.
     * @param  {object} [evaluationTypes] - The valid evaluation types for the object.
     * @param  {object} [options] - selfStatic, placeholders.
     * @returns {object}
     */
    ConfigEvaluator.prototype.evaluate = function evaluate(obj, evaluationTypes, options) {
        this._logger.debug('ConfigEvaluator.evaluate()', obj, evaluationTypes, options);
        var self = this;
        var trace = instrumentation.tracer.startTrace('ConfigEvaluator.evaluate', {componentModulePath: self.modulePath}, this._logger);
        this.references = [];
        this.evaluationTypes = evaluationTypes || {
            settings: true,
            model: true,
            labels: true,
            parameters: true,
            value: true,
            self: true,
            tokens: true
        };
        this.options = extend({
            selfStatic: null,
            placeholders: {} // custom placeholders that are provided up front
        }, options);
        if (!this.options.selfStatic) {
            this.evaluationTypes.self = false;
        }

        util.each(this.options.placeholders, function(obj, name) {
            self.evaluationTypes[name] = true;
        });

        var result = {
            reEvaluate: function reEvaluate() {
                var trace = instrumentation.tracer.startTrace('ConfigEvaluator.reEvaluate', {componentModulePath: self.modulePath}, this._loggerInstance);
                var cloned = util.isPlainObject(obj) || util.isArray(obj) ? JSON.parse(JSON.stringify(obj)) : obj;
                self.references.length = 0;
                var result = self._traverse(cloned);
                this.references = self.references.slice();
                trace.end(result);
                return result;
            }
        };

        result.evaluated = result.reEvaluate();

        // In general, when any reference is updated the object will be re-evaluated using reEvaluate.
        result.listen = function listenToAll(cb) {
            var unregisters = [];
            util.each(this.references, function(reference) {
                unregisters.push(reference.listen(cb));
            });

            return function off() {
                util.each(unregisters, function(f) {
                    f();
                });
            };
        };

        Object.defineProperty(result, 'hasValues', {
            get: function() {
                return !util.any(this.references, function hasValue(reference) {
                    return !reference.hasValue;
                });
            }
        });
        trace.end({obj: obj, evaluationTypes: evaluationTypes, options: options, result: result});
        return result;
    };

    ConfigEvaluator.prototype._hasMustache = function _hasMustache(str) {
        return singleMustacheRegex.test(str);
    };

    /**
     * Evaluates @str and if it contains a mustache expression
     * it evaluates the mustache expression/s and returns either a string or an object.
     * If there are multiple mustache expressions the returned value is always a string.
     * Also stores a cache of previously evaluated mustache expressions
     * @param  {string} str - A string that may contain a mustache expression.
     * @param  {string} path - The path taken to reach @str.
     * @returns {(string|object)}
     */
    ConfigEvaluator.prototype._evaluateString = function _evaluateString(str, path) {
        var self = this;
        if (!this._hasMustache(str)) {
            return str;
        }

        var source = {
            expression: str,
            path: path
        };

        // If we have evaluated this expression before, check if the value has changed since then...
        if (self._cache[source.expression]) {
            var hasChanged = false;
            util.each(self._cache[source.expression].referenceEntries, function(refEntry) {
                if (hasChanged) {
                    return;
                }

                if (refEntry == null) {
                    hasChanged = true;
                    return;
                }

                if (refEntry.prevValue !== refEntry.getCurrentValue()) {
                    hasChanged = true;
                }
            });

            // ...If it has not, return the old value.
            if (!hasChanged) {
                self.references.push.apply(self.references, self._cache[source.expression].references);
                return self._cache[source.expression].result;
            }
        }

        // If there's no hit in the cache or if it has been changed, further evaluation is needed.
        var result = null;
        if (exactlyOneMustacheRegex.test(str)) {
            // exactly one match (this may return other types than string), for example a model.
            var match = str.match(exactlyOneMustacheRegex);
            result = self._evaluateStringExpressions(match[1], match[0], source, true);
        } else {
            // multiple mustaches/string composition (will always return a string).
            result = str.replace(multiMustacheRegex, function(whole, inner) {
                return self._evaluateStringExpressions(inner, whole, source);
            });
        }

        self._cache[source.expression] = self._cache[source.expression] || {
            referenceEntries: []
        };
        self._cache[source.expression].result = result;
        self._cache[source.expression].references = this.references.slice();

        return result;
    };

    /**
     * Evaluates a single mustache expression and returns a value. If singleMatch is true, the returned value
     * can be anything, otherwise it will be a string.
     * @param  {string} str - The string to be evaluated.
     * @param  {string} mustache - A single mustache expression.
     * @param  {object} source - Contains the original string that @str and @mustache was part of and the path to that string.
     * @param  {bool} singleMatch - Indicates if the original string only contained one mustache expression.
     * @returns {(string|object)}
     */
    ConfigEvaluator.prototype._evaluateStringExpressions = function _evaluateStringExpressions(str, mustache, source, singleMatch) {
        this.hasStringValue = false;
        this.isObject = false;
        var original = str;
        var self = this;

        source.isSingleMatch = !!singleMatch;

        var obj = str;

        obj = self._evaluateStringExpressionTypes(obj, source, mustache, function instanceCallback(instance) {
            // retrieves the actual value from the components instance or the config evaluators options
            var type = this.type;
            if (self.options.placeholders[type]) {
                return self.options.placeholders[type];
            }

            if (type === 'self') {
                return self.options.selfStatic;
            } else if (type === 'tokens') {
                return self.context.getTokens();
            } else {
                return instance['get' + util.string.firstUpperCase(type)]();
            }
        });

        return obj === original ? mustache : (!this.hasStringValue ? (this.isObject ? obj : this._eval(obj)) : obj);
    };

    ConfigEvaluator.prototype._eval = function _eval(str) {
        if (this._isJsonSerialized(str)) {
            return JSON.parse(str);
        } else {
            str = str.trim();
            if (str === 'undefined') {
                return undefined;
            } else if (str === 'null') {
                return null;
            } else if (str === 'true') {
                return true;
            } else if (str === 'false') {
                return false;
            } else if (!isNaN(parseFloat(str)) && isFinite(str)) {
                return parseFloat(str);
            } else {
                str = str.replace(/-\s+-/g, '+'); // a - -b = a + b

                // str.match(regex) returns null or [originalExpr, match1, match2...]
                const cn = str.match(/(.+)\?(.+):(.+)/),
                    an = str.match(/(.+)&&(.+)/),
                    or = str.match(/(.+)\|\|(.+)/),
                    sm = str.match(/(.+)\+(.+)/),
                    ml = str.match(/(.+)\*(.+)/),
                    dv = str.match(/(.+)\/(.+)/),
                    ee = str.match(/(.+)===(.+)/),
                    nn = str.match(/(.+)!==(.+)/),
                    eq = str.match(/(.+)==(.+)/),
                    ne = str.match(/(.+)!=(.+)/),
                    le = str.match(/(.+)<=(.+)/),
                    ge = str.match(/(.+)>=(.+)/),
                    ls = str.match(/(.+)<(.+)/),
                    gr = str.match(/(.+)>(.+)/),
                    nx = str.match(/!([^=].*)/),
                    sb = str.match(/(.+)-(.+)/);

                if (cn && cn.length === 4) return this._eval(cn[1]) ?   this._eval(cn[2]) : this._eval(cn[3]); else
                if (or && or.length === 3) return this._eval(or[1]) ||  this._eval(or[2]); else
                if (an && an.length === 3) return this._eval(an[1]) &&  this._eval(an[2]); else
                if (sm && sm.length === 3) return this._eval(sm[1]) +   this._eval(sm[2]); else
                if (ml && ml.length === 3) return this._eval(ml[1]) *   this._eval(ml[2]); else
                if (dv && dv.length === 3) return this._eval(dv[1]) /   this._eval(dv[2]); else
                if (ee && ee.length === 3) return this._eval(ee[1]) === this._eval(ee[2]); else
                if (nn && nn.length === 3) return this._eval(nn[1]) !== this._eval(nn[2]); else
                if (eq && eq.length === 3) return this._eval(eq[1]) ==  this._eval(eq[2]); else
                if (ne && ne.length === 3) return this._eval(ne[1]) !=  this._eval(ne[2]); else
                if (le && le.length === 3) return this._eval(le[1]) <=  this._eval(le[2]); else
                if (ge && ge.length === 3) return this._eval(ge[1]) >=  this._eval(ge[2]); else
                if (ls && ls.length === 3) return this._eval(ls[1]) <   this._eval(ls[2]); else
                if (gr && gr.length === 3) return this._eval(gr[1]) >   this._eval(gr[2]); else
                if (nx && nx.length === 2) return !this._eval(nx[1]);                      else
                if (sb && sb.length === 3) return this._eval(sb[1]) -   this._eval(sb[2]); else return str;
            }
        }
    };

    ConfigEvaluator.prototype._isJsonSerialized = function _isJsonSerialized(str) {
        return str.indexOf('{') === 0 || str.indexOf('[') === 0;
    };

    /**
     * Converts the given mustache expression recursively to a string or an object.
     * @param  {string} str - The string to be evaluated.
     * @param  {string} mustache - A single mustache expression.
     * @param  {object} source - Contains the original string that @str and @mustache was part of and the path to that string.
     * @param  {requestCallback} instanceCallback - Used to retrieve value for the given type.
     * @returns {(string|object)}
     */
    ConfigEvaluator.prototype._evaluateStringExpressionTypes = function _evaluateStringExpressionTypes(str, source, mustache, instanceCallback) {
        var self = this;
        var obj = null;
        var activeTypes = [];
        util.each(this.evaluationTypes, function(active, type) {
            if (active) {
                activeTypes.push(type);
            }
        });

        if (!activeTypes.length) {
            return str;
        }

        var regex = new RegExp(['(', activeTypes.join('|'), ')'].join('') + baseRegex, 'g');
        str = str.replace(regex, function(match, type, relativeModulePath, propsExpression, formatType, format) {
            return (obj = self._getReplacementsRecursively(instanceCallback, relativeModulePath, propsExpression, formatType, format, type, source, mustache));
        });

        return this.isObject ? obj : str;
    };

    /**
     * Retrieves the value from the evaluated mustache expression.
     *
     * Example input:
     * model(_).a.b|number(abbr:k)
     * type(relativeModulePath).propsExpression|formatType(format)
     * @param  {requestCallback} instanceCallback - Used to retrieve value for the given type.
     * @param  {string} relativeModulePath - Relative path in the instance hierarchy to reach the target instance.
     * @param  {string} propsExpression - Property path in the referenced object.
     * @param  {string} formatType - date or number
     * @param  {string} format - the settings used by the @formatType.
     * @param  {string} type - The matched active type.
     * @param  {obj} source - Contains the original string that @str and @mustache was part of and the path to that string.
     * @param  {string} mustache - A single mustache expression.
     * @returns {(string|object)}
     */
    ConfigEvaluator.prototype._getReplacementsRecursively = function _getReplacementsRecursively(instanceCallback, relativeModulePath, propsExpression,
        formatType, format, type, source, mustache) {

        instanceCallback = instanceCallback.bind({
            type: type
        });

        var referencedInstance;
        try {
            referencedInstance = this.instanceRegistry.get(this.modulePath, relativeModulePath);
        } catch (ex) {
            this.hasStringValue = true;
            this._logger.error(new Error('ConfigEvaluator failed to evaluate expression'), {
                mustache: mustache,
                modulePath: this.modulePath
            });
            return mustache;
        }

        var self = this;

        if (!referencedInstance) {
            self._logger.fatal(new Error('ConfigEvaluator could not find referenced instance: ' + this.modulePath + ', ' +
                relativeModulePath));
        }

        var originalReference = {
            type: type,
            instance: referencedInstance,
            refPath: propsExpression || '',
            source: source,
            listen: this._createListener(type, propsExpression || '', referencedInstance),
            assign: this._createAssigner(type, propsExpression || '', referencedInstance),
            cacheKey: this._createCacheKey(type, propsExpression || '', referencedInstance),
            get: this._createGetter(propsExpression || '', referencedInstance, instanceCallback),
            eventType: this._getEventType(type, propsExpression || ''),
            mustache: mustache,
            modulePath: this.modulePath,
            relativeModulePath: relativeModulePath,
            formatType: formatType,
            format: format,
            evaluate: function evaluate() {
                var evaluator = new ConfigEvaluator(this.modulePath, self.instanceRegistry, self.context);
                return evaluator.evaluate(this.source.expression, self.evaluationTypes, self.options);
            }
        };

        var value = instanceCallback(referencedInstance);
        // Special setters/getters for self and placeholders.
        if (type === 'self' && self.options.selfStatic) {
            originalReference.assign = function assignSelfStatic(value) {
                morningstar.asterix.util.parse(propsExpression).assign(self.options.selfStatic, value);
            };
            originalReference.get = function getSelfStatic() {
                return morningstar.asterix.util.parse(propsExpression)(self.options.selfStatic);
            };
        } else if (self.options.placeholders[type]) {
            originalReference.assign = function assignPlaceholder(value) {
                morningstar.asterix.util.parse(propsExpression).assign(self.options.placeholders[type], value);
            };
            originalReference.get = function getPlaceholder() {
                return morningstar.asterix.util.parse(propsExpression)(self.options.placeholders[type]);
            };
        }

        // Retrieve value following propsExpression path if needed.
        value = propsExpression ? util.parse(propsExpression)(value) : value;
        if (self.options.extractPath) {
            var extractedPathValue = util.parse(self.options.extractPath)(value);
            if (extractedPathValue != null) {
                value = extractedPathValue;
            }
        }

        // 2017-11-08 - msteier: Is this actually used somewhere?
        if (this._hasMustache(value) && type !== 'model') {
            // in the returned references, we skip straight to the end of the "mustache chain"
            var relative = util.componentPath.relative(self.modulePath, referencedInstance.getModulePath());
            var evaluator = new ConfigEvaluator(referencedInstance.getModulePath(), this.instanceRegistry);
            var result = evaluator.evaluate(value);
            util.each(result.references, function(ref) {
                // replace modulePath, source and source expression for the reference and base relative module paths from self.modulePath
                var relativeModulePath = !relative ? ref.relativeModulePath :
                    util.componentPath.resolve(relative, ref.relativeModulePath);
                var replacementMustache = ref.mustache.replace('(' + ref.relativeModulePath + ')', '(' +
                    relativeModulePath + ')');
                if (formatType) {
                    // add the calling end of the chain's format to the mustache
                    var formatString = '|' + formatType + (format ? '(' + format + ')' : '');
                    replacementMustache = replacementMustache.replace(/(?:\|.*?)?(\s*\_\}\})/, function(match, end) {
                        return formatString + end;
                    });
                }

                ref.modulePath = self.modulePath;
                ref.source = originalReference.source;
                ref.source.expression = ref.source.expression.replace(mustache, replacementMustache);
            });
            Array.prototype.push.apply(this.references, result.references);
            value = result.evaluated;

            util.each(result.references, function(reference) {
                self._cacheReferenceEntry(reference);
            });
        } else {
            // no mustache in the returned value -- use original reference
            this.references.push(originalReference);
            self._cacheReferenceEntry(originalReference);
        }

        originalReference.value = value;
        originalReference.hasValue = value != null;

        // Format value before returning.
        var originalValue = value;
        value = this._getFormatted(value, this.instanceRegistry.get(this.modulePath), formatType, format);
        if (source.isSingleMatch && morningstar.asterix.util.isObject(originalValue)) {
            this.isObject = true;
            value = originalValue;
        }

        return this.hasStringValue === true ? value : (this.isObject ? value : JSON.stringify(value));
    };

    ConfigEvaluator.prototype._cacheReferenceEntry = function _cacheReferenceEntry(reference) {
        this._cache[reference.source.expression] = this._cache[reference.source.expression] || {
            referenceEntries: {}
        };
        // only cache expressions that contain non-object types (to avoid keeping deep copies around)
        this._cache[reference.source.expression].referenceEntries[reference.cacheKey] = util.isObject(reference.get()) ? null : {
            prevValue: reference.get(),
            getCurrentValue: reference.get
        };
    };

    ConfigEvaluator.prototype._getEventType = function _getEventType(type, refPath) {
        switch (type) {
            case 'parameters':
                if (refPath) {
                    return ['parameterChanged', refPath].join(':');
                } else {
                    return 'parametersChanged';
                }
            case 'model':
                if (refPath) {
                    return ['modelPropertyChanged', refPath].join(':');
                } else {
                    return 'modelChanged';
                }
            case 'value':
                if (refPath) {
                    this._logger.fatal(new Error('refPath for reference of type "value" in ConfigEvaluator is not implemented yet'));
                } else {
                    return 'valueChanged';
                }
            case 'tokens':
                if (refPath) {
                    return ['tokenChanged', refPath].join(':');
                } else {
                    this._logger.fatal(new Error('can\'t use a tokens expression without a refPath'));
                }
        }

        return null;
    };

    ConfigEvaluator.prototype._createCacheKey = function _createCacheKey(type, refPath, instance) {
        return refPath ? [instance.getModulePath(), type, refPath].join(':') : [instance.getModulePath(), type].join(':');
    };

    ConfigEvaluator.prototype._createAssigner = function _createAssigner(type, refPath, instance) {
        return function assign(value) {
            switch (type) {
                case 'parameters':
                    if (!refPath) {
                        instance.setParameters(value);
                    } else {
                        instance.setParameter(refPath, value);
                    }
                    break;
                case 'model':
                    if (!refPath) {
                        instance.setModel(value);
                    } else {
                        instance.setModelProperty(refPath, value);
                    }
                    break;
                default:
                    this._logger.fatal(new Error('can\'t use reference "assign" method for type ' + type));
            }
        };
    };

    ConfigEvaluator.prototype._createGetter = function _createGetter(refPath, instance, instanceCallback) {
        return function get() {
            var baseValue = instanceCallback(instance);
            return refPath ? morningstar.asterix.util.parse(refPath)(baseValue) : baseValue;
        };
    };

    ConfigEvaluator.prototype._createListener = function _createListener(type, refPath, instance) {
        var self = this;
        return function(onChangeCallback) {
            var listenerType = self._getEventType(type, refPath);
            if (listenerType == null) {
                return util.noop;
            }

            if (listenerType.indexOf('tokenChanged') === 0) {
                return self.context.onTokenChanged(listenerType.split(':')[1], onChangeCallback);
            }

            return instance.on(listenerType, onChangeCallback);
        };
    };

    ConfigEvaluator.prototype._getFormatted = function _getFormatted(value, instance, formatType, format) {
        if (formatType == null) {
            if (util.isString(value)) {
                this.hasStringValue = true;
            }

            return value;
        }

        this.hasStringValue = true;
        var formatter = instance.getFormatter();
        if (formatType === 'number') {
            value = formatter.number(value, {
                formatString: format
            });
        } else if (formatType === 'date') {
            value = formatter.date(value, {
                formatString: format
            });
        }

        return value;
    };

    /**
     * Recursively traverses each property in the obj until it reaches a string value,
     * while keeping track of the path taken to reach the string value.
     * When reaching a string value it evaluates it.
     * @param  {} obj - Object to be traversed.
     * @param  {} path - Path to current obj.
     */
    ConfigEvaluator.prototype._traverse = function _traverse(obj, path) {
        path = path || '';
        var self = this;
        if (util.isString(obj)) {
            return self._evaluateString(obj, path);
        } else if (util.isPlainObject(obj) || util.isArray(obj)) {
            var originalPath = path;
            util.each(obj, function(value, prop) {
                path = originalPath ? (originalPath + '.' + prop) : prop;
                obj[prop] = self._traverse(value, path);
            });
        }

        return obj;
    };

    return ConfigEvaluator;
});
