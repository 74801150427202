define(['./each.js', './merge.js', '../instrumentation.js'], function(each, merge, instrumentation) {
    'use strict';

    return {
        resolve: resolve,
        relative: relative,
        assignInstance: assignInstance
    };

    function assignInstance(modulePath, appConfig, defaultConfig, type, instanceConfig, cb) {
        var curr = appConfig;
        var parts = modulePath.split('.');
        var addAt;
        each(parts, function(part, i) {
            if (curr.components && curr.components[part]) {
                curr = curr.components[part];
            } else if (curr.type && defaultConfig[curr.type] &&
                defaultConfig[curr.type].components[part]) {
                curr = defaultConfig[curr.type].components[part];
            } else {
                if (i === parts.length - 1) {
                    if (part.indexOf('[') >= 0) {
                        part = part.substring(0, part.lastIndexOf('['));
                        if (curr.components && curr.components[part]) {
                            instanceConfig = merge({}, defaultConfig && defaultConfig[curr.type] && defaultConfig[curr.type].components ? defaultConfig[curr.type].components[part] : {}, curr.components[part], instanceConfig);
                        } else if (defaultConfig[curr.type] && defaultConfig[curr.type].components && defaultConfig[curr.type].components[part]) {
                            instanceConfig = merge({}, defaultConfig[curr.type].components[part], instanceConfig);
                        }
                    }

                    if (!curr.components) {
                        curr.components = {};
                    }

                    addAt = curr.components;
                } else {
                    instrumentation.logger.fatal(new Error('(util) Could not assign instance dynamically because a parent instance was not initialized: ' +
                        part));
                }
            }
        });

        if (addAt) {
            instanceConfig = instanceConfig || {components: {}};
            instanceConfig.type = type;
            addAt[parts[parts.length - 1]] = instanceConfig;
        }

        if (cb) {
            cb(modulePath);
        }
    }

    function resolve(currentPath, relativePath) {
        if (!relativePath) {
            return currentPath;
        }

        if (currentPath == null) {
            instrumentation.logger.fatal(new Error('currentPath must have a value'));
        }

        var currentSegments = currentPath.split('.');
        if (currentSegments[0] === '') {
            currentSegments.splice(0, 1);
        }

        var relativeSegments = relativePath.split('.');
        var resultingSegments = [];
        var hasSpecifiedSegment = false;
        each(relativeSegments, function(segment) {
            if (segment === '_') {
                if (hasSpecifiedSegment) {
                    instrumentation.logger.fatal(new Error('Invalid relative path: ' + relativePath));
                } else {
                    if (!currentSegments.pop()) {
                        instrumentation.logger.fatal(new Error('Invalid relative path lookup: ' + currentPath + ', ' + relativePath));
                    }
                }
            } else {
                resultingSegments.push(segment);
                hasSpecifiedSegment = true;
            }
        });

        return currentSegments.join('.') + (currentSegments.length && resultingSegments.length ? '.' : '') + resultingSegments.join('.');
    }

    function relative(from, to) {
        var s1 = !from ? [] : from.split('.'),
            s2 = !to ? [] : to.split('.');
        var up = [], down = [], noConflicts = true;
        for (var i = 0; i < Math.max(s1.length, s2.length); i++) {
            if (s1.length > i && s2.length > i) {
                if (s1[i] == s2[i] && noConflicts) {
                    continue;
                } else {
                    up.push('_');
                    down.push(s2[i]);
                    noConflicts = false;
                }
            } else if (s1.length > i) {
                up.push('_');
            } else if (s2.length > i) {
                down.push(s2[i]);
            }
        }

        return up.concat(down).join('.');
    }
});
