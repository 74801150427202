define([], function() {
    'use strict';

    function ProviderCollection() {
        this._providers = {};
    }

    ProviderCollection.prototype.register = function(name, provider) {
        if (!provider) {
            throw 'Cannot register undefined/null provider - ' + name;
        }
        this._providers[name] = provider;
    };
    ProviderCollection.prototype.unregister = function(name) {
        delete this._providers[name];
    };
    ProviderCollection.prototype.clear = function() {
        this._providers = {};
    };
    ProviderCollection.prototype.hasProviders = function() {
        var self = this;
        for (var name in self._providers) {
            if (self._providers.hasOwnProperty(name)) {
                return true;
            }
        }
        return false;
    };
    ProviderCollection.prototype.get = function(name) {
        return this._providers[name];
    };
    ProviderCollection.prototype.each = function(iteratee) {
        var self = this;
        for (var name in self._providers) {
            if (self._providers.hasOwnProperty(name)) {
                iteratee(self._providers[name]);
            }
        }
    };

    return ProviderCollection;
});
