define([], function() {
    'use strict';
    /**
     * Determines if a variable object is an object.
     * @function module:morningstar.asterix.util/isObject
     * @param  {any}  object any type of object
     * @return {Boolean} true if object that is not null, otherwise false
     */
    var isObject = function(object) {
        return typeof object === 'object' && object !== null;
    };

    return isObject;
});
