define(['./each.js',
        './is-object',
        '../util/is-date.js',
        '../util/is-string.js',
        '../util/is-number.js',
        '../instrumentation.js',
        '../util/merge.js',
        '../util/clone-object.js',
        '../util/is-valid-date.js'
    ],
    function(each, isObject, isDate, isString, isNumber, instrumentation, merge, cloneObject, isValidDate) {
        'use strict';

        var cache = {};
        var valueCache = {};

        var defaults = {
            nullString: '–'
        };

        var _essentialProps = ['year', 'month', 'day', 'hour', 'minute', 'second'];

        return {
            getFormatter: getDateFormatter
        };

        function _buildKeySegments(segments, options) {
            each(options, function(v, k) {
                segments.push(k);
                if (isObject(v)) {
                    _buildKeySegments(segments, v);
                } else {
                    segments.push(v);
                }
            });
        }

        function getDateFormatter(culture, options, formatAliases) {
            options = options || {};
            options = translateAlias(options, formatAliases);

            var key = [];
            key.push(culture);
            _buildKeySegments(key, options);
            key = key.join('|');

            if (!cache[key]) {
                cache[key] = createFormatter(culture, options, formatAliases);
            }

            return cache[key];
        }

        function translateAlias(alias, formatAliases) {
            if (!isString(alias)) {
                return alias;
            }

            var result = formatAliases[alias];
            if (!result) {
                instrumentation.logger.fatal(new Error('Could not find format alias: ' + alias));
            }

            return result;
        }

        function createFormatter(culture, options, formatAliases) {
            options = merge({}, defaults, options || {});

            var _intlDateFormat;
            if (window.Intl) {
                _intlDateFormat = window.Intl.DateTimeFormat(options.culture || culture, options);
            } else {
                _intlDateFormat = {
                    format: function(v) {
                        if (isDate(v)) {
                            return new Date(v).toDateString();
                        }
                        return v;
                    }
                }
            }

            var cache = {};

            return {
                format: function format(value, optionOverrides) {
                    if (optionOverrides && (isString(optionOverrides) || Object.keys(optionOverrides).length)) {
                        optionOverrides = translateAlias(optionOverrides, formatAliases);

                        var hasEssentialProp = false;

                        each(_essentialProps, function(prop) {
                            if (optionOverrides.hasOwnProperty(prop)) {
                                hasEssentialProp = true;
                            }
                        });

                        var opts;
                        if (hasEssentialProp === true) {
                            opts = cloneObject(optionOverrides);
                            if (!opts.nullString) {
                                opts.nullString = options.nullString;
                            }
                        } else {
                            opts = merge({}, options, optionOverrides);
                        }

                        return getDateFormatter(opts.culture || culture, opts).format(value);
                    }

                    if (value == null) {
                        return options.nullString;
                    }

                    var cacheKey = value ? value.toString() : 'null';
                    if (cache[cacheKey]) {
                        return cache[cacheKey];
                    }

                    if (!isDate(value)) {
                        if (isString(value) && !isValidDate(new Date(value))) {
                            // Change to yyyy/mm/dd hh:mm:ss if current format fails
                            value = value.replace(/-/g, '/');
                            value = value.replace('T', ' ');
                        }
                        if (isString(value) || isNumber(value)) {
                            value = valueCache[value] = valueCache[value] || new Date(value);
                        } else {
                            return cache[cacheKey] = value;
                        }
                    }

                    var result = _intlDateFormat.format(value);

                    cache[cacheKey] = result;

                    return result;
                }
            };
        }
    });
