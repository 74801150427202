define(['./util.js', './extend.js', './instrumentation.js'], /** @lends morningstar.asterix.Namespace */ function(util, extend, instrumentation) {
    'use strict';

    /**
     * Represents namespacing for morningstar components. All components may
     * register themselves using morningstar.asterix.register. This will make them
     * available under the morningstar.components global.
     *
     * @class
     * @constructor
     * @author dcolli1
     * @modifier jou - removed class and constuctor.
     */
    return (function() {
        var _morningstarNoConflict;

        init();

        return {
            init: init,
            register: register,
            reset: reset,
            noConflict: noConflict,
            getDefaultConfig: getDefaultConfig
        };

        function init() {
            _morningstarNoConflict = window.morningstar; //todo i think we need to use cloneOjb here.

            window.morningstar = window.morningstar || {};
            if (!window.morningstar.asterix) {
                var morningstarRoot = extend(true, {}, {
                    name: 'morningstar',
                    asterix: {},
                    components: {
                        _defaultConfig: {},
                        _appConfig: {
                            settings: {},
                            components: {}
                        }
                    }
                }, window.morningstar);

                window.morningstar = morningstarRoot;
            } else {
                instrumentation.logger.debug('Tried to create morningstar.asterix multiple times.');
            }
        }

        /**
         * Registers a new component
         * @param {string} name - The component name to register
         * @param {object} defaultConfig - The default component configuration
         * @return {object} components namespace
         */
        function register(name, defaultConfig) {
            var parts = name.split('.');

            if (parts[0] === window.morningstar.name) {
                parts = parts.slice(1);
            }

            if (parts[0] === 'components') {
                parts = parts.slice(1);
            }

            var partsLength = parts.length,
                parent = window.morningstar.components;

            for (var m = 0; m < partsLength; m++) {
                if (typeof parent[parts[m]] === 'undefined') {
                    parent[parts[m]] = {};
                }

                parent = parent[parts[m]];
            }

            if (defaultConfig) {
                // register default config for component type, if given
                var type = parts.join('.');
                window.morningstar.components.defaultConfig = window.morningstar.components.defaultConfig || {};
                parent.defaultConfig = window.morningstar.components._defaultConfig[type] = defaultConfig;
                parent.defaultConfig.type = type;
                parent.instances = {};
                parent.registerInstance = function registerInstance(modulePath, instanceConfig) {
                    instanceConfig = instanceConfig || {};
                    instanceConfig.type = type;
                    parent.instances[modulePath] = instanceConfig;
                    util.componentPath.assignInstance(modulePath, window.morningstar.components._appConfig, window.morningstar.components._defaultConfig, type, instanceConfig, null);
                };
            }

            return window.morningstar;
        }

        function getDefaultConfig(type) {
            if (!type) {
                return window.morningstar.components._defaultConfig || {};
            } else {
                return (window.morningstar.components._defaultConfig || {})[type];
            }
        }

        /**
         *
         * @return {object}
         */
        function noConflict() {
            window[window.morningstar.name] = _morningstarNoConflict;
            return window.morningstar;
        }

        function reset() {
            window.morningstar.components = {
                _defaultConfig: {},
                _appConfig: {
                    settings: {},
                    components: {}
                }
            };
        }

    }());

});
