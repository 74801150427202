define(['./is-plain-object.js', './is-undefined.js', './is-undefined-or-null.js'], function(isPlainObject, isUndefined, isUndefinedOrNull) {
    'use strict';

    /**
     * Recursively merges defaults and instance objects.
     * IMPORTANT - Copies objects by reference so config can share objects, making it more efficient. Use util.merge to clone objects.
     *             If the setting freezeConfig is true then this will be called otherwise util.merge will be called (can only share config objects if they are frozen).
     * Undefined instance properties are skipped if a default value exists.
     * Arrays are not merged, either the complete default or instance value (if defined) is taken.
     * Merges properties inherited from the object's prototype as well as own properties.
     *
     * Use merge instead (based on lodash merge) to also merge arrays recursively and skip inherited properties:
     * https://lodash.com/docs/4.17.4#merge
     */

    lightMerge.prop = _lightMergeProp;

    return lightMerge;

    function _lightMergeProp(prop, defaults, instance, result) {
        if ((isUndefinedOrNull(instance) || isUndefined(instance[prop])) && !isUndefined(defaults[prop])) {
            result[prop] = defaults[prop];
        } else if (!isUndefined(instance[prop])) {
            if (isPlainObject(instance[prop])) {
                if (!isUndefinedOrNull(defaults[prop]) && isPlainObject(defaults[prop])) {
                    result[prop] = lightMerge(defaults[prop], instance[prop]);
                } else {
                    result[prop] = instance[prop];
                }
            } else {
                result[prop] = instance[prop];
            }
        }
    }

    function lightMerge(defaults, instance) {
        var result = {};

        for (var prop in defaults) {
            _lightMergeProp(prop, defaults, instance, result);
        }

        for (var prop in instance) {
            if (defaults && defaults.hasOwnProperty(prop)) {
                continue;
            }

            _lightMergeProp(prop, defaults, instance, result);
        }

        return result;
    }

});
