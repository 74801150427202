define(['./each', '../extend', '../instrumentation.js'], function(each, extend, instrumentation) {
    'use strict';

    var cache = {};

    function parse(expression) {
        expression = expression || '';

        if (cache[expression]) {
            return cache[expression];
        }

        var parts = !expression ? [] : expression.replace(']', '').split(/[.\[]/),
            body = [];

        body.push('var curr = obj;');
        each(parts, function(part) {
            body.push('if (!curr) return curr;');
            body.push('curr = curr["' + part + '"];');
        });

        body.push('return curr;');

        var parsed = new Function('obj', body.join(''));
        parsed.assign = function assign(obj, value) {
            if (!obj) {
                instrumentation.logger.fatal(new Error('util.parse.assign expects its first argument to be an object'));
            }

            if (!parts.length) {
                extend(true, obj, value);
                return;
            }

            var curr = obj,
                prevCurr, currPart;
            each(parts, function(part, index) {
                currPart = part;
                prevCurr = curr;
                curr = curr[part];
                if (curr == null) {
                    if (index < parts.length - 1 && !isNaN(parts[index + 1])) {
                        // next part is an array index, so the current part should be initialized to an empty array
                        prevCurr[part] = [];
                    } else {
                        prevCurr[part] = {};
                    }

                    curr = prevCurr[part];
                }
            });

            if (!prevCurr || !currPart) {
                return;
            }

            prevCurr[currPart] = value;
        };

        return cache[expression] = parsed;
    };

    return parse;
});
