define(['morningstar'], function(morningstar) {
    'use strict';

    /**
     * To append morningstar component directive to client's container
     * @param {string} morningstarComponentId - the component instance name
     * @param {object} container - the container object (e.g. document.getElementById('containerId'))
     * @return {object} - component instance
     */
    function init(morningstarComponentId, params) {
        morningstar.asterix.instrumentation.logger.debug('Initialising component', morningstarComponentId, params);
        var instanceConfig = morningstar.asterix.config.getInstanceConfig(morningstarComponentId);
        if (instanceConfig) {
            var componentType = morningstar.asterix.util.string.dashCase(instanceConfig.type);
            if (/^ec-/.test(componentType)) { // mean it is a EC component will build the tag
                var componentNode = document.createElement(componentType);
                componentNode.setAttribute('mstar-component-id', morningstarComponentId);
                if (morningstar.asterix.util.isDomElement(params.container)) {
                    params.container.appendChild(componentNode);
                } else {
                    morningstar.asterix.instrumentation.logger.debug('params.container is not a object.');
                }
                return morningstar.asterix.instanceRegistry.get(morningstarComponentId);
            } else if (/^markets-components-/.test(componentType)) {
                if (instanceConfig.labels) {
                    instanceConfig.settings.labels = morningstar.asterix.util.merge(instanceConfig.labels, instanceConfig.settings.labels || {});
                }
                return morningstar.components[componentType].createComponent({
                    element: params.container,
                    initialConfiguration: params.initialConfiguration || {
                        component: {
                            configuration: instanceConfig.settings,
                            callbacks: instanceConfig.settings.callbacks
                        }
                    }
                });
            }
        } else {
            morningstar.asterix.instrumentation.logger.warn('Invalid morningstar component instance with id:' + morningstarComponentId);
            return null;
        }
    }

    return {
        init: init
    };
});
