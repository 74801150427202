define([], function() {
    'use strict';
    var parameterize = function(urlTemplate, parameters) {
        var url = urlTemplate;
        if (url && parameters) {
            url = url.replace(/\{\{\s*(.*?)\s*\}\}/g, function(match, group) {
                if (parameters.hasOwnProperty(group)) {
                    return parameters[group];
                }
                return match;
            });
        }

        return url;
    };

    var unescapeBaseUrl = function(url) {
        var result = url || '';

        if (!!url) {
            var baseUrlProbe = url.match(/(https?:)?\/\/[^\/]*/i);
            if (!!baseUrlProbe && baseUrlProbe.length) {
                result = url.replace(baseUrlProbe[0], decodeURIComponent(baseUrlProbe[0]));
            }
        }

        return result;
    }

    return {
        parameterize: parameterize,
        unescapeBaseUrl: unescapeBaseUrl
    };
});
