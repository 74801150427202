define([], function() {
    'use strict';
    return {
        isFunction: require('./util/is-function'),
        isArray: require('./util/is-array'),
        isBoolean: require('./util/is-boolean'),
        isPlainObject: require('./util/is-plain-object'),
        isWindow: require('./util/is-window'),
        isDate: require('./util/is-date'),
        isValidDate: require('./util/is-valid-date'),
        isEmptyObject: require('./util/is-empty-object'),
        isNumber: require('./util/is-number'),
        isZero: require('./util/is-zero'),
        isString: require('./util/is-string'),
        isRegex: require('./util/is-regex'),
        isUndefined: require('./util/is-undefined'),
        isUndefinedOrNull: require('./util/is-undefined-or-null'),
        isObject: require('./util/is-object'),
        isDomElement: require('./util/is-dom-element'),
        getPrototype: require('./util/get-prototype'),
        noop: require('./util/noop'),
        url: require('./util/url'),
        parse: require('./util/parse'),
        each: require('./util/each'),
        filter: require('./util/filter'),
        any: require('./util/any'),
        has: require('./util/has'),
        toString: require('./util/to-string'),
        isEqual: require('./util/is-equal'),
        componentPath: require('./util/component-path'),
        string: require('./util/string'),
        merge: require('./util/merge'),
        map: require('./util/map'),
        css: require('./util/css'),
        intlNumberFormat: require('./util/intl-number-format'),
        intlDateFormat: require('./util/intl-date-format'),
        log: require('./util/log'),
        field: require('./util/field'),
        fastdom: require('../../vendor/fastdom/fastdom'),
        ensureObjectPaths: require('./util/ensure-object-paths'),
        date: require('./util/date'),
        freeze: require('./util/freeze'),
        lightMerge: require('./util/light-merge'),
        cloneObject: require('./util/clone-object'),
        fixSplitForD3: require('./util/fix-split-for-d3'),
    };
});
