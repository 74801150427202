define(['./util', './instrumentation', '../config/default-base-labels.json', '../config/default-base-settings.json'], function(util, instrumentation, defaultBaseLabels, defaultBaseSettings) {
    'use strict';

    var inheritedSettings = {
        baseUrlApiGatewayEU: true,
        baseUrlApiGatewayUS: true,
        baseUrlMorningstarEU: true,
        baseUrlMorningstarUS: true,
        baseUrlMwcCdn: true,
        baseUrlPdfServiceEU: true,
        baseUrlQuoteSpeed: true,
        baseUrlQuoteSpeedPull: true,
        currencyId: true,
        currencySymbol: true,
        defaultDateFormat: true,
        defaultNumberFormat: true,
        format: true,
        freezeConfig: true,
        languageId: true,
        urlKey: true,
        useCalculatedWidth: true,
        validation: true,
        _validation: true
    };

    var _defaultConfiguration = null,
        _applicationConfiguration = null,
        _originalApplicationConfiguration = null,
        _baseLabels = null,
        _baseSettings = null,
        _options,
        _componentInstanceConfigCache = {},
        _componentInstanceSettingsCache = {},
        _componentInstanceLabelsCache = {},
        _componentInstanceLanguageLabelsCache = {},
        _componentInstanceTypeCache = {},
        _modulePaths = {},
        _defaultConfigFragmentCache = {},
        _defaultSettingsFragmentCache = {},
        _instanceSettingsFragmentCache = {},
        _defaultLabelsFragmentCache = {},
        _instanceLabelsFragmentCache = {},
        _typeConfigs = {},
        _dynamicallyAddedInstances = {},
        _typeSettingKeys = {},
        _freezeConfig = false,
        _defaultBaseLabels = defaultBaseLabels || {},
        _defaultBaseSettings = util.merge({
            languageId: 'en-GB'
        }, defaultBaseSettings),
        _mwcComponents = new Map();

    function getNonUnderscored(obj) {
        var newObj = {};
        util.each(obj, function(v, k) {
            if (k.charAt(0) !== '_') {
                if (util.isPlainObject(v)) {
                    newObj[k] = merge({}, v);
                } else {
                    newObj[k] = v;
                }
            }
        });

        return newObj;
    }

    function getUnderscored(obj) {
        var newObj = {};
        util.each(obj, function(v, k) {
            if (k.charAt(0) === '_') {
                if (util.isPlainObject(v)) {
                    newObj[k] = merge({}, v);
                } else {
                    newObj[k] = v;
                }
            }
        });

        return newObj;
    }

    /**
     * Merges the target and source objects.
     * If freezeConfig is true then lightMerge is called
     * which copies objects by reference to improve performance.
     * @param {*} target
     * @param {*} source
     */
    function merge(target, source) {
        var result;
        if (_freezeConfig) {
            result = util.lightMerge(target, source);
        } else {
            result = util.merge({}, target, source, mergeCustomizer);
        }
        return result;
    }

    function mergeCustomizer(a, b, key) {
        if (util.isArray(b)) {
            return b;
        }
    }

    /**
     * Replaces values in __env object with environment specific value,
     * e.g. to call correct API (QA, UAT, Production...)
     * @param {*} obj
     * @param {*} path
     */
    function applyEnv(obj, path) {
        path = path || '';
        if (path.indexOf('settings') >= 0 && obj.__env) {
            var env = obj.__env[_options.env];
            if (env) {
                util.merge(obj, env);
            }
        }

        util.each(obj, function(v, k) {
            if (util.isPlainObject(v) && ['labels'].indexOf(k) < 0) {
                obj[k] = applyEnv(v, path ? path + '.' + k : k);
            }
        });

        return obj;
    }

    /**
     * Get and cache the default config for the specified component.
     * Merges the component type's default config with that specified for the component by its ancestors.
     * @param {string} path - full path to the component, e.g. xray.assetAllocation
     * @param {string} type - type of the component, e.g. ecAssetAllocation
     * @param {object} currentDefaultConfig - default config for the component set by its ancestors
     */
    function defaultConfigCacher(path, type, currentDefaultConfig) {
        if (_defaultConfigFragmentCache[path]) {
            return _defaultConfigFragmentCache[path];
        }
        var typeExtended = _defaultConfiguration[type] || {};

        instrumentation.logger.debug('config.defaultConfigCacher()', type, path, {
            name: 'typeDefaultConfig',
            value: typeExtended
        });
        return _defaultConfigFragmentCache[path] = merge(typeExtended, currentDefaultConfig);
    }

    /**
     * Get and cache the default settings for the specified component.
     * Merges the inheritable settings from ancestor config with the default settings for the component.
     * @param {string} path - full path to the component, e.g. xray.assetAllocation
     * @param {object} currentDefaultSettings - settings from ancestors
     * @param {object} currentDefaultConfig - default config for the component
     */
    function defaultSettingsCacher(path, currentDefaultSettings, currentDefaultConfig) {
        if (_defaultSettingsFragmentCache[path]) {
            return _defaultSettingsFragmentCache[path];
        }
        return _defaultSettingsFragmentCache[path] = util.merge(getNonUnderscored(currentDefaultSettings),
            currentDefaultConfig && currentDefaultConfig.settings ? currentDefaultConfig.settings : {}, mergeCustomizer);
    }

    /**
     * Get and cache the instance settings for the specified component.
     * Merges the private default settings for the component with inherited settings from ancestors and the instance settings.
     * @param {string} path - full path to the component, e.g. xray.assetAllocation
     * @param {object} currentInstance - current instance config
     * @param {object} currentSettings - settings from ancestors
     * @param {object} currentDefaultSettings - default settings for the component
     */
    function instanceSettingsCacher(path, currentInstance, currentSettings, currentDefaultSettings) {
        if (_instanceSettingsFragmentCache[path]) {
            return _instanceSettingsFragmentCache[path];
        }
        return _instanceSettingsFragmentCache[path] = util.merge(getUnderscored(currentDefaultSettings),
            getNonUnderscored(currentSettings), currentInstance.settings, mergeCustomizer);
    }

    function defaultLabelsCacher(path, currentDefaultLabels, currentDefaultConfig) {
        if (_defaultLabelsFragmentCache[path]) {
            return _defaultLabelsFragmentCache[path];
        }
        var currentDefaultConfigLabels = currentDefaultConfig && currentDefaultConfig.labels ?
            currentDefaultConfig.labels : {};
        var result = {};
        for (var prop in currentDefaultLabels) {
            if (prop.charAt(0) === '_') {
                continue;
            }

            util.lightMerge.prop(prop, currentDefaultLabels, currentDefaultConfigLabels || {}, result);
        }

        for (var prop in currentDefaultConfigLabels) {
            if (result.hasOwnProperty(prop)) {
                continue;
            }

            result[prop] = currentDefaultConfigLabels[prop];
        }
        return _defaultLabelsFragmentCache[path] = result;
    }

    function instanceLabelsCacher(path, currentInstance, currentLabels, currentDefaultLabels) {
        if (_instanceLabelsFragmentCache[path]) {
            return _instanceLabelsFragmentCache[path];
        }
        _instanceLabelsFragmentCache[path] = util.merge(getUnderscored(currentDefaultLabels), getNonUnderscored(currentLabels), currentInstance.labels, mergeCustomizer);

        return _instanceLabelsFragmentCache[path];
    }

    function storeTypeConfigs(typeConfigs) {
        util.each(typeConfigs, function(typeConfig, type) {
            if (_typeConfigs[type]) {
                _typeConfigs[type] = util.merge({}, _typeConfigs[type], typeConfig);
            } else {
                _typeConfigs[type] = typeConfig;
            }
        });
    }

    function getComponentInstanceConfig(modulePath) {
        if (!modulePath) {
            return { settings: instanceSettingsCacher('', {}, _applicationConfiguration.settings, {}) };
        }

        var originalPath = modulePath;
        modulePath = getCachedBaseModulePath(modulePath);

        if (_componentInstanceConfigCache[modulePath]) {
            _componentInstanceTypeCache[originalPath] = _componentInstanceTypeCache[modulePath];
            return _componentInstanceConfigCache[modulePath];
        }

        var trace = instrumentation.tracer.startTrace('config.getComponentInstanceConfig()', {
            componentModulePath: modulePath
        });

        var currentInstanceConfig = _applicationConfiguration,
            currentSettings = currentInstanceConfig.settings,
            currentLabels = currentInstanceConfig.labels,
            currentDefaultConfig = {},
            currentDefaultSettings = {},
            currentDefaultLabels = {},
            currentType;

        var paths = modulePath.split('.');
        var currFull = '';

        for (var i = 0; i < paths.length; i++) {
            var pathFragment = paths[i];
            var originalCurrFull = currFull += (currFull ? '.' : '') + pathFragment;
            currFull = getCachedBaseModulePath(currFull);
            if (currFull !== originalCurrFull) {
                pathFragment = currFull.split('.')[currFull.split('.').length - 1];
            }

            currFull = originalCurrFull;

            // Get the component's instance config from the parent component. The parent is the main appConfig object on the first pass.
            currentInstanceConfig = currentInstanceConfig.components &&
                currentInstanceConfig.components[pathFragment] ?
                currentInstanceConfig.components[pathFragment] : {};

            // Get the default config for the component
            currentDefaultConfig = currentDefaultConfig && currentDefaultConfig.components &&
                currentDefaultConfig.components[pathFragment] ?
                currentDefaultConfig.components[pathFragment] : {};

            // Get the type from the instance or default config
            currentType = currentInstanceConfig.type || currentDefaultConfig.type || (_defaultConfiguration[currFull] ? currFull : undefined);

            instrumentation.logger.debug('config.getComponentInstanceConfig()', currentType, currFull, modulePath, {
                name: 'ancestorDefaultConfig',
                value: currentDefaultConfig
            });

            // Cache/get the default config
            // This is the default config for the type merged with the current default config for the component
            currentDefaultConfig = defaultConfigCacher(currFull, currentType, currentDefaultConfig);

            instrumentation.logger.debug('config.getComponentInstanceConfig()', currentType, currFull, modulePath, {
                name: 'ancestorInstanceConfig',
                value: currentInstanceConfig
            });

            // Cache/get the current instance config
            // This is a merge of blueprints, default type overrides and instance type overrides with the current instance config
            currentInstanceConfig = instanceConfigCacher(currFull, currentInstanceConfig, currentDefaultConfig, currentType);

            // Cache/get default settings and labels
            // This is a merge of non-underscored default settings and settings from the default config object
            currentInstanceConfig.defaultSettings = currentDefaultSettings = defaultSettingsCacher(currFull, currentDefaultSettings, currentDefaultConfig);
            currentInstanceConfig.defaultLabels = currentDefaultLabels = defaultLabelsCacher(currFull, currentDefaultLabels, currentDefaultConfig);

            // Cache/get instance settings and labels
            // This is a merge of underscored default settings, non-underscored settings inherited from the parent and settings from the current instance
            currentInstanceConfig.settings = currentSettings = instanceSettingsCacher(currFull, currentInstanceConfig, currentSettings, currentDefaultSettings);
            currentInstanceConfig.labels = currentLabels = instanceLabelsCacher(currFull, currentInstanceConfig, currentLabels, currentDefaultLabels);

            _componentInstanceTypeCache[currFull] = currentType;
            if (!_componentInstanceTypeCache[currFull]) {
                instrumentation.logger.fatal(new Error('Invalid component type for modulePath: ' + currFull));
            }
        }

        trace.end({
            currentInstanceConfig: currentInstanceConfig
        });

        return currentInstanceConfig;
    }

    function getTypeSettingKeys(type) {
        if (_typeSettingKeys[type]) {
            return _typeSettingKeys[type];
        }

        var result = util.merge({}, inheritedSettings);
        if (_defaultConfiguration[type] && _defaultConfiguration[type].settings) {
            if (_defaultConfiguration[type].settings.__allowed) {
                for (var i = 0; i < _defaultConfiguration[type].settings.__allowed.length; i++) {
                    result[_defaultConfiguration[type].settings.__allowed[i]] = true;
                }
            }

            for (var key in _defaultConfiguration[type].settings) {
                result[key] = true;
            }
        }

        return _typeSettingKeys[type] = result;
    }

    /**
     * Get and cache the instance config for the specified component.
     * Merges default and instance overrides for the component type with blueprints and instance config.
     * @param {string} currFull - full path to the component, e.g. xray.assetAllocation
     * @param {object} currentInstanceConfig - current instance config
     * @param {object} currentDefaultConfig - current default config
     * @param {string} currentType - type of the component, e.g. ecAssetAllocation
     */
    function instanceConfigCacher(currFull, currentInstanceConfig, currentDefaultConfig, currentType) {
        if (_componentInstanceConfigCache[currFull]) {
            return _componentInstanceConfigCache[currFull];
        }

        var trace = instrumentation.tracer.startTrace('config.instanceConfigCacher()', {
            componentModulePath: currFull
        });

        var blueprintToApply = !util.isUndefined(currentInstanceConfig.blueprint) ? currentInstanceConfig.blueprint : currentDefaultConfig.blueprint;
        if (currentDefaultConfig.blueprints && blueprintToApply && currentDefaultConfig.blueprints[blueprintToApply]) {
            var blueprintConfig = currentDefaultConfig.blueprints[blueprintToApply];
            instrumentation.logger.debug('config.instanceConfigCacher()', currentType, currFull, blueprintToApply, {
                name: 'blueprintConfig',
                value: blueprintConfig
            });
            currentInstanceConfig = util.merge({}, blueprintConfig, currentInstanceConfig, mergeCustomizer);
        }

        if (currentDefaultConfig.types) {
            instrumentation.logger.debug('config.instanceConfigCacher()', currentType, currFull, {
                name: 'typesConfigFromDefault',
                value: currentDefaultConfig.types
            });
            storeTypeConfigs(currentDefaultConfig.types);
        }

        if (currentInstanceConfig.types) {
            instrumentation.logger.debug('config.instanceConfigCacher()', currentType, currFull, {
                name: 'typesConfigFromInstance',
                value: currentInstanceConfig.types
            });
            storeTypeConfigs(currentInstanceConfig.types);
        }

        if (_typeConfigs[currentType]) {
            instrumentation.logger.debug('config.instanceConfigCacher()', currentType, currFull, {
                name: 'typeConfigFromInstance',
                value: _typeConfigs[currentType]
            });
            currentInstanceConfig = util.merge({}, _typeConfigs[currentType], currentInstanceConfig, mergeCustomizer);
        }

        _componentInstanceConfigCache[currFull] = currentInstanceConfig;

        trace.end({
            currentInstanceConfig: currentInstanceConfig
        });

        return _componentInstanceConfigCache[currFull];
    }

    // if the instance wasn't pre-configured, return its base module path instead (for performance reasons)
    function getCachedBaseModulePath(modulePath) {
        if (modulePath.lastIndexOf(']') === modulePath.length - 1 && _dynamicallyAddedInstances[modulePath]) {
            return getCachedBaseModulePath(modulePath.substring(0, modulePath.lastIndexOf('[')));
        }

        return modulePath;
    }

    /**
     * Get and cache the instance settings for the specified component.
     * Merges base, component default and component instance settings.
     * Removes settings not associated with component type
     * (this prevents all settings being inherited from ancestor components)
     * @param {string} modulePath - full path to the component, e.g. xray.assetAllocation
     */
    function getComponentInstanceSettings(modulePath) {
        modulePath = getCachedBaseModulePath(modulePath);

        if (_componentInstanceSettingsCache[modulePath]) {
            return _componentInstanceSettingsCache[modulePath];
        }

        var trace = instrumentation.tracer.startTrace('config.getComponentInstanceSettings()', {
            componentModulePath: modulePath
        });
        var instanceConfig = getComponentInstanceConfig(modulePath);

        var currentInstanceSettingsTemp = merge(instanceConfig.defaultSettings || {}, instanceConfig.settings);
        var currentInstanceSettings = merge(_baseSettings, currentInstanceSettingsTemp);
        if (modulePath && !_mwcComponents.get(modulePath)) {
            currentInstanceSettings = getModuleSpecificInstanceSettings(currentInstanceSettings, _componentInstanceTypeCache[modulePath]);
        }

        if (_freezeConfig) {
            util.freeze.deep(currentInstanceSettings);
        }

        _componentInstanceSettingsCache[modulePath] = currentInstanceSettings;

        trace.end({
            componentInstanceSettings: currentInstanceSettings
        });
        return _componentInstanceSettingsCache[modulePath];
    }

    function getModuleSpecificInstanceSettings(settings, moduleType) {
        var settingKeys = getTypeSettingKeys(moduleType);

        var keys = Object.keys(settings);
        for (var i = 0; i < keys.length; i++) {
            if (!settingKeys.hasOwnProperty(keys[i])) {
                delete settings[keys[i]];
            }
        }

        return settings;
    }

    function getComponentInstanceLabels(modulePath, languageId) {
        modulePath = getCachedBaseModulePath(modulePath);

        var moduleSpecificInstanceLabels = _componentInstanceLabelsCache[modulePath];
        if (!moduleSpecificInstanceLabels) {

            var instanceConfig = getComponentInstanceConfig(modulePath);
            var currentDefaultLabels = merge(instanceConfig.defaultLabels, instanceConfig.labels);

            moduleSpecificInstanceLabels = currentDefaultLabels;
            _componentInstanceLabelsCache[modulePath] = moduleSpecificInstanceLabels;
        }

        var languageLabels = _componentInstanceLanguageLabelsCache[modulePath] &&
            _componentInstanceLanguageLabelsCache[modulePath][languageId] ?
            _componentInstanceLanguageLabelsCache[modulePath][languageId] : null;
        if (!languageLabels) {
            var trace = instrumentation.tracer.startTrace('config.getComponentInstanceLabels()', {
                componentModulePath: modulePath
            });

            languageLabels = {};

            // todo: fix implementation to support x1-x2-x3-x4-x5-..-xn
            var baseLanguage = null;
            if (languageId.split('-').length > 1) {
                baseLanguage = languageId.split('-')[0];
            }

            for (var baseLabelKey in _baseLabels) {
                var baseLabelValue = _baseLabels[baseLabelKey];

                if (baseLabelValue[languageId]) {
                    languageLabels[baseLabelKey] = baseLabelValue[languageId];
                } else if (baseLanguage && baseLabelValue[baseLanguage]) {
                    languageLabels[baseLabelKey] = baseLabelValue[baseLanguage];
                } else if (baseLabelValue.en) {
                    // default to en if no label found for languageId
                    languageLabels[baseLabelKey] = baseLabelValue.en;
                }
            }

            for (var moduleSpecificInstanceLabelKey in moduleSpecificInstanceLabels) {
                var moduleSpecificInstanceLabelValue = moduleSpecificInstanceLabels[moduleSpecificInstanceLabelKey];
                if (!moduleSpecificInstanceLabelValue) {
                    return;
                }

                if (moduleSpecificInstanceLabelValue[languageId]) {
                    languageLabels[moduleSpecificInstanceLabelKey] = moduleSpecificInstanceLabelValue[languageId];
                } else if (baseLanguage && moduleSpecificInstanceLabelValue[baseLanguage]) {
                    languageLabels[moduleSpecificInstanceLabelKey] = moduleSpecificInstanceLabelValue[baseLanguage];
                } else if (moduleSpecificInstanceLabelValue.en && !languageLabels[moduleSpecificInstanceLabelKey]) {
                    // default to en if no label found for languageId
                    languageLabels[moduleSpecificInstanceLabelKey] = moduleSpecificInstanceLabelValue.en;
                }
            }

            if (!_componentInstanceLanguageLabelsCache[modulePath]) {
                _componentInstanceLanguageLabelsCache[modulePath] = {};
            }

            _componentInstanceLanguageLabelsCache[modulePath][languageId] = languageLabels;
            trace.end({
                languageLabels: languageLabels,
                languageId: languageId
            });
        }

        return languageLabels;
    }

    function getComponentInstanceType(modulePath) {
        var trace = instrumentation.tracer.startTrace('config.getComponentInstanceType()', {
            componentModulePath: modulePath
        });
        modulePath = getCachedBaseModulePath(modulePath);
        getComponentInstanceConfig(modulePath);
        trace.end({
            type: _componentInstanceTypeCache[modulePath]
        });
        return _componentInstanceTypeCache[modulePath];
    }

    function getSetting(modulePath, settingKey) {
        return getComponentInstanceSettings(modulePath)[settingKey];
    }

    function init(applicationConfig, defaultConfig, baseLabelsIn, baseSettingsIn, optionsIn) {
        var trace = instrumentation.tracer.startTrace('config.init()');
        if (!applicationConfig) {
            instrumentation.logger.fatal(new Error('(config) No applicationConfig provided'));
        }

        if (!defaultConfig) {
            instrumentation.logger.fatal(new Error('(config) No defaultConfig provided'));
        }

        _componentInstanceConfigCache = {};
        _componentInstanceSettingsCache = {};
        _componentInstanceLabelsCache = {};
        _componentInstanceLanguageLabelsCache = {};
        _componentInstanceTypeCache = {};
        _modulePaths = {};
        _typeSettingKeys = {};
        _defaultConfigFragmentCache = {};
        _defaultSettingsFragmentCache = {};
        _instanceSettingsFragmentCache = {};
        _defaultLabelsFragmentCache = {};
        _instanceLabelsFragmentCache = {};
        _applicationConfiguration = util.merge({}, applicationConfig);
        _originalApplicationConfiguration = applicationConfig;
        _defaultConfiguration = util.merge({}, defaultConfig);
        _baseLabels = util.merge({}, _defaultBaseLabels, baseLabelsIn);
        _baseSettings = util.merge({}, _defaultBaseSettings, baseSettingsIn);
        _dynamicallyAddedInstances = {};
        _options = optionsIn || {};

        _freezeConfig = _baseSettings.freezeConfig;
        if (applicationConfig.settings && !util.isUndefined(applicationConfig.settings.freezeConfig)) {
            _freezeConfig = applicationConfig.settings.freezeConfig;
        }

        if (!_options.hasOwnProperty('dynamic')) {
            _options.dynamic = false;
        }
        util.each(Object.keys(_baseSettings), function(key) {
            inheritedSettings[key] = true;
        });

        applyEnv({
            settings: _baseSettings
        }); //Apply env for default base settings
        applyEnv(_applicationConfiguration);
        applyEnv(_defaultConfiguration);

        registerModulePaths(applicationConfig.components);

        if (typeof window !== 'undefined' && util.ensureObjectPaths(window, 'mwc.configuration')) {
            const mwcConfig = window.mwc.configuration;
            mwcConfig.configuration = mwcConfig.configuration || {};
            mwcConfig.configuration.settings = mwcConfig.configuration.settings || {};
            mwcConfig.configuration.settings = getComponentInstanceSettings('');
        }

        trace.end({
            applicationConfig: applicationConfig,
            defaultConfig: defaultConfig,
            baseLabelsIn: baseLabelsIn,
            baseSettingsIn: baseSettingsIn,
            optionsIn: optionsIn
        });
    }

    /**
     * All component paths are registered here in the modulePaths object.
     * Components are added from the appConfig, defaultConfig and blueprints.
     * modulePaths is then used to register components in the instance registry.
     * @param {*} components
     * @param {*} pathPrefix
     */
    function registerModulePaths(components, pathPrefix) {
        if (Object.keys(components).length === 0) {
            return;
        }

        util.each(components, function(component, pathFragment) {
            var fullPath = (pathPrefix ? pathPrefix + '.' : '') + pathFragment;
            if (!_modulePaths.hasOwnProperty(fullPath)) {
                _modulePaths[fullPath] = true;
            }

            if (!component.type) {
                instrumentation.logger.fatal(new Error('Missing component type for path: ' + fullPath));
            }

            if (_defaultConfiguration.hasOwnProperty(component.type)) {
                var blueprints = _defaultConfiguration[component.type].blueprints;
                if (component.blueprint && blueprints && blueprints[component.blueprint] && util.isPlainObject(blueprints[component.blueprint].components)) {
                    // merge the blueprint's components with the default config's components due to the type being inferred in the blueprint components sometimes
                    registerModulePaths(util.merge({}, _defaultConfiguration[component.type].components, blueprints[component.blueprint].components), fullPath);
                } else if (util.isPlainObject(_defaultConfiguration[component.type].components)) {
                    registerModulePaths(_defaultConfiguration[component.type].components, fullPath);
                }
            }

            if (util.isPlainObject(component.components)) {
                registerModulePaths(component.components, fullPath);
            }
        });
    }

    function getModulePaths() {
        return _modulePaths;
    }

    function getComponentType(modulePath) {
        return getComponentInstanceType(modulePath);
    }

    function hasType(type) {
        return !!_defaultConfiguration[type];
    }

    function hasInstance(modulePath) {
        return !!_modulePaths[modulePath];
    }

    function addInstanceInternal(modulePath, opts) {
        if (!modulePath || !opts || !opts.type) {
            instrumentation.logger.fatal(new Error('(config) addInstanceInternal requires modulePath and opts (with type)'));
        }

        util.componentPath.assignInstance(modulePath, _applicationConfiguration, _defaultConfiguration, opts.type, opts.instanceConfig, function(modulePath) {
            _modulePaths[modulePath] = true;
            _dynamicallyAddedInstances[modulePath] = true;
        });
    }

    function allowsDynamic() {
        return !_options || _options.dynamic;
    }

    function getDefaultConfigFromNameSpace(type) { // TODO: Handle this some way.
        if (!type) {
            return typeof window !== 'undefined' && window.morningstar.components._defaultConfig || {};
        } else {
            return (typeof window !== 'undefined' && window.morningstar.components._defaultConfig || {})[type];
        }
    }

    function addType(type, defaultConfig) {
        instrumentation.logger.info('config.addType()', type, defaultConfig);
        if (!allowsDynamic()) {
            instrumentation.logger.fatal(new Error('(config) The configuration module has been configured not to allow dynamic behaviour.'));
        }

        defaultConfig = defaultConfig || getDefaultConfigFromNameSpace(type);

        if (!type || !defaultConfig) {
            instrumentation.logger.fatal(new Error('(config) _addType requires type and defaultConfig'));
        }

        if (!defaultConfig.type) {
            defaultConfig.type = type;
        }

        _defaultConfiguration[type] = defaultConfig;
    }

    function registerMwcComponent(type, defaultConfig, path, parentPath, childComponent) {
        instrumentation.logger.info('config.registerMwcComponent()', type, defaultConfig);

        if (!allowsDynamic()) {
            instrumentation.logger.fatal(new Error('(config) The configuration module has been configured not to allow dynamic behaviour.'));
        }

        if (!type || !defaultConfig) {
            instrumentation.logger.fatal(new Error('(config) registerMwcComponent requires type and defaultConfig'));
        }

        if (!defaultConfig.type) {
            defaultConfig.type = type;
        }

        _defaultConfiguration[type] = defaultConfig;
        _typeSettingKeys[type] = undefined;

        if (path && path.indexOf('[') >= 0) {
            _dynamicallyAddedInstances[path] = true;
        }

        let clearCache = true;
        if (!_modulePaths[path]) {
            clearCache = false;
            if (util.isUndefinedOrNull(parentPath) || !parentPath) {
                // If there is no appConfig for the path add a minimal one here so we can get the type later on
                if (!_applicationConfiguration.components) {
                    _applicationConfiguration.components = {};
                }
                if (!_applicationConfiguration.components[path]) {
                    _applicationConfiguration.components[path] = {
                        type: type
                    };
                }
            }
        }
        _modulePaths[path] = true;

        if (parentPath) {
            var parentType = getComponentInstanceType(parentPath) || parentPath;
            var parentConfig = _defaultConfiguration[parentType];
            if (!parentConfig.components || !parentConfig.components[childComponent] || !parentConfig.components[childComponent].type) {
                // Add a minimal config to the parent for the child component so we can get the type later on
                updateDefaultConfigForInstance(parentPath, childComponent, {
                    type: type
                });
                clearCache = false; // updateDefaultConfigForInstance clears cache.
            }
        }

        // If we don't already have the component in the registry, add it now
        // This is so we can work with mwc components that are only in the page and not the config
        if (!morningstar.asterix.instanceRegistry.find(path)) {
            morningstar.asterix.instanceRegistry.init(path);
        }

        // If the component was already registered then clear the cache so the defaultConfig passed in is picked up
        if (clearCache) {
            clearConfigCache(parentPath);
        }

        // If we already have an instance in the registry reset it so the new element is associated with it
        if (_mwcComponents.get(path)) {
            const mwcInstance = morningstar.asterix.instanceRegistry.get(path);
            if (mwcInstance) {
                mwcInstance.restoreInstance();
            }
        }

        _mwcComponents.set(path, true);
    }

    /**
     * If someone has injected a config into the component it should be set in the parent components object.
     * @param {string} parentPath - Path to the components parent.
     * @param {string} componentName - The name of the component instance.
     * @param {*} injectedConfig - The injected configuration for the component.
     */
    function updateDefaultConfigForInstance(parentPath, componentName, injectedConfig) {
        if (componentName.indexOf('[') >= 0) {
            componentName = componentName.substring(0, componentName.lastIndexOf('['));
        }
        var path = parentPath.concat('.', componentName);
        var parentType = getComponentInstanceType(parentPath) || parentPath;
        var parentConfig = _defaultConfiguration[parentType];

        if (!parentConfig) {
            instrumentation.logger.fatal(new Error('Missing default for modulePath: ' + parentPath + ' with type: ' + parentType));
        }

        // Invalidate caches for any path containing parent since the config for the parent has changed
        _typeSettingKeys[parentType] = undefined; // Should this actually be needed?
        clearConfigCache(parentPath);

        // Merge the values in the components parents components section.
        if (!parentConfig.components) {
            parentConfig.components = {};
        }
        parentConfig.components[componentName] = util.merge({}, parentConfig.components[componentName], injectedConfig);
        _modulePaths[path] = true;
    }

    function clearConfigCache(parentPath) {
        if (!parentPath) {
            parentPath = '';
        }
        util.each(_componentInstanceSettingsCache, function(cacheValue, key) {
            if (key.indexOf(parentPath) > -1) {
                _componentInstanceSettingsCache[key] = undefined;
            }
        });
        util.each(_componentInstanceConfigCache, function(cacheValue, key) {
            if (key.indexOf(parentPath) > -1) {
                _componentInstanceConfigCache[key] = undefined;
            }
        });
        util.each(_componentInstanceLabelsCache, function(cacheValue, key) {
            if (key.indexOf(parentPath) > -1) {
                _componentInstanceLabelsCache[key] = undefined;
            }
        });
        util.each(_componentInstanceLanguageLabelsCache, function(cacheValue, key) {
            if (key.indexOf(parentPath) > -1) {
                _componentInstanceLanguageLabelsCache[key] = undefined;
            }
        });
        util.each(_defaultConfigFragmentCache, function(cacheValue, key) {
            if (key.indexOf(parentPath) > -1) {
                _defaultConfigFragmentCache[key] = undefined;
            }
        });
        util.each(_defaultLabelsFragmentCache, function(cacheValue, key) {
            if (key.indexOf(parentPath) > -1) {
                _defaultLabelsFragmentCache[key] = undefined;
            }
        });
        util.each(_defaultSettingsFragmentCache, function(cacheValue, key) {
            if (key.indexOf(parentPath) > -1) {
                _defaultSettingsFragmentCache[key] = undefined;
            }
        });
    }

    function addInstance(modulePath, opts) {
        instrumentation.logger.info('config.addInstance()', modulePath, opts);
        if (!canAddModulePathDynamically(modulePath)) {
            instrumentation.logger.fatal(new Error('(config) The configuration module has been configured not to allow dynamic behaviour.'));
        }

        if (!modulePath || !opts) {
            instrumentation.logger.fatal(new Error('(config) addInstance requires modulePath and options'));
        }

        if (!opts.type) {
            instrumentation.logger.fatal(new Error('(config) addInstance requires options.type'));
        }

        if (!hasType(opts.type)) {
            addType(opts.type, opts.defaultConfig);
        }

        if (!hasInstance(modulePath)) {
            addInstanceInternal(modulePath, opts);
        }
    }

    function canAddModulePathDynamically(modulePath) {
        return allowsDynamic() || (modulePath && modulePath.indexOf('[') >= 0 &&
            hasInstance(modulePath.substring(0, modulePath.lastIndexOf('['))));
    }

    function setAppConfigMwc(appConfig) {
        if (typeof window !== 'undefined' && util.ensureObjectPaths(window, 'mwc.configuration.configuration')) {
            window.mwc.configuration.configuration = util.merge({}, window.mwc.configuration.configuration, appConfig);
        }
        const newAppConfig = { settings: {}};
        if (util.ensureObjectPaths(appConfig, 'settings.sal')) {
            newAppConfig.settings.sal = util.merge({}, appConfig.settings.sal);
        }
        _applicationConfiguration = util.merge(_applicationConfiguration, newAppConfig);

        _componentInstanceSettingsCache = {};
        _instanceSettingsFragmentCache = {};
        _componentInstanceConfigCache = {};
    }

    return {
        init: init,
        getSetting: getSetting,
        getSettings: getComponentInstanceSettings,
        getLabels: getComponentInstanceLabels,
        getComponentType: getComponentType,
        getModulePaths: getModulePaths,
        addType: addType,
        addInstance: addInstance,
        hasType: hasType,
        hasInstance: hasInstance,
        canAddModulePathDynamically: canAddModulePathDynamically,
        allowsDynamic: allowsDynamic,
        /**
         * Note: instanceId can only be a top level instance.
         * @param instanceId
         * @returns {*}
         */
        getInstanceConfig: function getInstanceConfig(instanceId) {
            return !_originalApplicationConfiguration || !_originalApplicationConfiguration.components ||
                !_originalApplicationConfiguration.components[instanceId] ? null :
                _originalApplicationConfiguration.components[instanceId];
        },
        getDefaultConfig: function getDefaultConfig(type) {
            return !_defaultConfiguration || !_defaultConfiguration[type] ? null : _defaultConfiguration[type];
        },
        getOption: function getOption(key) {
            return (_options || {})[key];
        },
        setOption: function setOption(key, value) {
            _options = _options || {};
            _options[key] = value;
        },
        getBaseSettings: function getBaseSettings() {
            return _defaultBaseSettings;
        },
        mergeBaseSettings: function mergeBaseSettings(settings) {
            _defaultBaseSettings = util.merge({}, _defaultBaseSettings, settings);
        },
        util: util,
        registerMwcComponent: registerMwcComponent,
        updateDefaultConfigForInstance: updateDefaultConfigForInstance,
        registerModulePaths: registerModulePaths,
        setAppConfig: setAppConfigMwc
    };
});
