define(['../validation'], function(validation) {
    'use strict';
    validation.addValidationRule('required', {
        type: 'custom',
        runFalsy: true,
        execute: function(val, options) {
            return typeof val !== 'undefined' && val !== null && val !== '';
        }
    });
});
