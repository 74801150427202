define(['./is-function.js', './has.js', './to-string.js', './each.js'], function(isFunction, has, toString, each) {
    'use strict';

    // Internal recursive comparison function for `isEqual`.
    var eq, deepEq, getKeys;
    eq = function(a, b, aStack, bStack) {
        // Identical objects are equal. `0 === -0`, but they aren't identical.
        // See the [Harmony `egal` proposal](http://wiki.ecmascript.org/doku.php?id=harmony:egal).
        if (a === b) {
            return a !== 0 || 1 / a === 1 / b;
        }
        // A strict comparison is necessary because `null == undefined`.
        if (a == null || b == null) {
            return a === b;
        }
        // `NaN`s are equivalent, but non-reflexive.
        if (a !== a) {
            return b !== b;
        }
        // Exhaust primitive checks
        var type = typeof a;
        if (type === 'function' && typeof b === 'function') {
            return true;
        }

        if (type !== 'function' && type !== 'object' && typeof b !== 'object') {
            return false;
        }
        return deepEq(a, b, aStack, bStack);
    };

    // Internal recursive comparison function for `isEqual`.
    deepEq = function(a, b, aStack, bStack) {
        // Compare `[[Class]]` names.
        var className = toString(a);
        if (className !== toString(b)) {
            return false;
        }
        switch (className) {
            // Strings, numbers, regular expressions, dates, and booleans are compared by value.
            case '[object RegExp]':
            // RegExps are coerced to strings for comparison (Note: '' + /a/i === '/a/i')
            case '[object String]':
                // Primitives and their corresponding object wrappers are equivalent; thus, `"5"` is
                // equivalent to `new String("5")`.
                return '' + a === '' + b;
            case '[object Number]':
                // `NaN`s are equivalent, but non-reflexive.
                // Object(NaN) is equivalent to NaN
                if (+a !== +a) {
                    return +b !== +b;
                }
                // An `egal` comparison is performed for other numeric values.
                return +a === 0 ? 1 / +a === 1 / b : +a === +b;
            case '[object Date]':
            case '[object Boolean]':
                // Coerce dates and booleans to numeric primitive values. Dates are compared by their
                // millisecond representations. Note that invalid dates with millisecond representations
                // of `NaN` are not equivalent.
                return +a === +b;
        }

        var areArrays = className === '[object Array]';
        if (!areArrays) {
            if (typeof a != 'object' || typeof b != 'object') {
                return false;
            }

            // Objects with different constructors are not equivalent, but `Object`s or `Array`s
            // from different frames are.
            var aCtor = a.constructor, bCtor = b.constructor;
            if (aCtor !== bCtor && !(isFunction(aCtor) && aCtor instanceof aCtor &&
                isFunction(bCtor) && bCtor instanceof bCtor) &&
                ('constructor' in a && 'constructor' in b)) {
                return false;
            }
        }
        // Assume equality for cyclic structures. The algorithm for detecting cyclic
        // structures is adapted from ES 5.1 section 15.12.3, abstract operation `JO`.

        // Initializing stack of traversed objects.
        // It's done here since we only need them for objects and arrays comparison.
        aStack = aStack || [];
        bStack = bStack || [];
        var length = aStack.length;
        while (length--) {
            // Linear search. Performance is inversely proportional to the number of
            // unique nested structures.
            if (aStack[length] === a) {
                return bStack[length] === b;
            }
        }

        // Add the first object to the stack of traversed objects.
        aStack.push(a);
        bStack.push(b);

        // Recursively compare objects and arrays.
        if (areArrays) {
            // Compare array lengths to determine if a deep comparison is necessary.
            var aKeys = getKeys(a),
                bKeys = getKeys(b);
            if (aKeys.length !== bKeys.length) {
                return false;
            }

            // Deep compare the contents
            for (var key in aKeys) {
                key = aKeys[key];
                if (!eq(a[key], b[key], aStack, bStack)) {
                    return false;
                }
            }
        } else {
            // Deep compare objects.
            var keys = getKeys(a), key;
            length = keys.length;
            // Ensure that both objects contain the same number of properties before comparing deep equality.
            if (getKeys(b).length !== length) {
                return false;
            }
            while (length--) {
                // Deep compare each member
                key = keys[length];
                if (!(has(b, key) && eq(a[key], b[key], aStack, bStack))) {
                    return false;
                }
            }
        }
        // Remove the first object from the stack of traversed objects.
        aStack.pop();
        bStack.pop();
        return true;
    };

    getKeys = function(obj) {
        var returnKeys = [];

        if (obj) {
            var keys = Object.keys(obj);
            for (var index = 0; index < keys.length; index++) {
                if (keys[index].indexOf('$') !== 0) {
                    returnKeys.push(keys[index]);
                }
            }
        }

        return returnKeys;
    };

    // Perform a deep comparison to check if two objects are equal.
    var isEqual = function(a, b) {
        return eq(a, b);
    };

    return isEqual;
});
