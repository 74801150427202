define(['../validation'], function(validation) {
    'use strict';
    validation.addValidationRule('alpha', {
        type: 'regex',
        pattern: /^([A-Za-zÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ])+$/
    });

    validation.addValidationRule('alphaSpaces', {
        type: 'regex',
        pattern: /^([A-Za-zÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ\s])+$/
    });

    validation.addValidationRule('alphaNumeric', {
        type: 'regex',
        pattern: /^([A-Za-z0-9ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ])+$/
    });

    validation.addValidationRule('alphaNumSpaces', {
        type: 'regex',
        pattern: /^([A-Za-z0-9ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ\s])+$/
    });

    validation.addValidationRule('alphaDash', {
        type: 'regex',
        pattern: /^([A-Za-z0-9ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ_-])+$/
    });

    validation.addValidationRule('alphaDashSpaces', {
        type: 'regex',
        pattern: /^([A-Za-z0-9ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ\s_-])+$/
    });
});
