define([], function() {
    'use strict';

    var each = function(obj, iteratee, context) {
        if (obj == null) {
            return obj;
        }
        //iteratee = optimizeCb(iteratee, context);
        var i, length = obj.length;
        if (length === +length) {
            for (i = 0; i < length; i++) {
                iteratee(obj[i], i, obj);
            }
        } else {
            var keys = Object.keys(obj);
            for (i = 0, length = keys.length; i < length; i++) {
                iteratee(obj[keys[i]], keys[i], obj);
            }
        }
        return obj;
    };

    return each;

});
