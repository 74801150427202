define(['./util/is-function.js', './util/is-array.js', './util/is-plain-object.js'], function(isFunction, isArray, isPlainObject) {
    /**
     * Packaged javascript object extends from jQuery ($.extend) with the notable exception
     * that $.extend({}) does not extend the morningstar.asterix or morningstar namespace.
     *
     * NOTE that this has been re-written to support getters and setters (property definitions). This means
     * that for basic structure merging/extensions it's better to use util.merge (based on Lodash's merge) or underscore's extend (for a more normal extend behavior).
     *
     * Notably, when extending readonly structures (e.g. settings retrieved from the configuration module) DO NOT use this.
     *
     * @class
     * @param {*|boolean} [target|deep copy] [object to extend or flag for deep copy]
     * @param {*} [target] [target if deep copy specified]
     * @param {...*} [sources] [sources to extend target with, may be multiple]
     * @return {object} extended object
     * @author dcolli1
     */
    'use strict';

    function extend() {
        var src, copyIsArray, copy, name, options, clone,
            target = arguments[0] || {},
            i = 1,
            length = arguments.length,
            deep = false;

        if (length === 1) {
            return;
        }

        // Handle a deep copy situation
        if (typeof target === 'boolean') {
            deep = target;
            target = arguments[1] || {};
            // skip the boolean and the target
            i = 2;
        }

        // Handle case when target is a string or something (possible in deep copy)
        if (typeof target !== 'object' && !isFunction(target)) {
            target = {};
        }

        for (; i < length; i++) {
            // Only deal with non-null/undefined values
            if ((options = arguments[i]) != null) {
                // extend the base object
                var props = Object.getOwnPropertyNames(options || {});
                for (var j in props) {
                    if (props.hasOwnProperty(j)) {
                        name = props[j];
                        if (name === '_$parent') {
                            continue;
                        }

                        src = target[name];
                        copy = options[name];

                        // Prevent never-ending loop
                        if (target === copy) {
                            continue;
                        }

                        var copyDescriptor = Object.getOwnPropertyDescriptor(options, name);
                        if (copyDescriptor === undefined) {
                            continue;
                        }

                        var isAccessor = copyDescriptor.get || copyDescriptor.set || !copyDescriptor.enumerable || !copyDescriptor.writable;

                        // Recurse if we're merging plain objects or arrays
                        if (!isAccessor && deep && copy && (isPlainObject(copy) || (copyIsArray = isArray(copy)))) {
                            if (copyIsArray) {
                                copyIsArray = false;
                                clone = src && isArray(src) ? src : [];
                            } else {
                                clone = src && isPlainObject(src) ? src : {};
                            }

                            // Never move original objects, clone them
                            target[name] = extend(deep, clone, copy);
                            Object.defineProperty(target[name], '_$parent', {
                                enumerable: false,
                                value: target
                            });

                            // Don't bring in undefined values
                        } else if (copy !== undefined) {
                            Object.defineProperty(target, name, copyDescriptor);
                        }
                    }
                }
            }
        }

        // Return the modified object
        return target;
    }

    return extend;
});
