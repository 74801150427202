define(['./is-array.js'], function(isArray) {
    'use strict';

    var any = function(obj, predicate) {
        var keys = !isArray(obj) && Object.keys(obj),
            length = (keys || obj).length;
        for (var index = 0; index < length; index++) {
            var currentKey = keys ? keys[index] : index;
            if (predicate(obj[currentKey], currentKey, obj)) {
                return true;
            }
        }

        return false;
    };

    return any;
});
