define(['./is-number.js', './is-date.js', '../instrumentation.js'], function(isNumber, isDate, instrumentation) {
    'use strict';

    /**
     * Update the date via the number of month
     * @function module:morningstar.asterix.util.date.addMonths
     * Such as date = 2016-11-02, number = -5,the result date = 2016-06-02
     * Such as date = 2015-02-28,number = 12,the result date = 2016-02-29
     * @param  {Object}  date date object
     * @param  {Number}  number increase or decrease the number of months, the number is positive means increase, negative means decrease
     * @return {Object}  return a new date
     */
    function addMonths(date, number) {
        if (!isDate(date)) {
            instrumentation.logger.fatal(new Error('The date is invalid'));
        }

        if (!isNumber(number)) {
            instrumentation.logger.fatal(new Error('The number is invalid'));
        }

        var lastDay = getMonthEndDate(date).getDate();
        if (lastDay === date.getDate()) {
            date.setDate(1);
            date.setMonth(date.getMonth() + number);
            date.setDate(getMonthEndDate(date).getDate());
        } else {
            date.setMonth(date.getMonth() + number);
        }

        return date;
    }

    /**
     * Extracts the date part from a datetime string
     * @function module:morningstar.asterix.util.date.extractDateFromDatetime
     * Such as datetime 2018-02-05 14:41:50 returns just 2018-02-05
     * @param {string} datetime
     *
     */
    function extractDateFromDatetime(datetime) {
        return datetime && datetime.split ? datetime.split(' ')[0] : '';
    }

    function getMonthEndDate(date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    /**
     * Calculate diff month between two dates
     * @function module:morningstar.asterix.util.date.monthDiff
     * Such as from = 2016-11-02, to= 2017-05-02,the result 6
     * @param  {Object} date date object
     * @param  {Object} date date object
     * @return {Number} return diff month
     */
    function monthDiff(from, to) {
        if (!isDate(from) || !isDate(to)) {
            instrumentation.logger.fatal(new Error('The date is invalid'));
        }

        if (from > to) {
            instrumentation.logger.fatal(new Error('from must be less than to'));
        }

        return (to.getFullYear() - from.getFullYear()) * 12 - from.getMonth() + to.getMonth();
    }

    /**
     * @function module:morningstar.asterix.util.date.convertToUTC
     * @param  {Object} date date object e.g. new Date().
     * @return {Object} UTC date
     */
    function convertToUTC(date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }

    /**
     * To return this date string format: 2017-01-31T00:00:00.000
     * @function module:morningstar.asterix.util.date.toLocalISOString
     * @param {Object} utcDate
     * @return {String}
     */
    function toLocalISOString(utcDate) {
        return utcDate.toISOString().replace('Z', '');
    }

    /**
     * Convert to standard date discard time zone
     * @function module:morningstar.asterix.util.date.convertToStandard
     * @param  {Object} date date object e.g. new Date().
     * @return {Object} date
     */
    function convertToStandard(date) {
        date = convertToUTC(date);
        if (date.getTimezoneOffset() > 0) {
            date.setUTCMinutes(date.getTimezoneOffset() * 2);
        }
        return date;
    }

    /**
     * Convert to standard date discard time zone
     * @function module:morningstar.asterix.util.date.utcToStandard
     * @param  {Object} utcDate
     * @return {Object} date
     */
    function utcToStandard(utcDate) {
        if (utcDate.getTimezoneOffset() > 0) {
            utcDate.setUTCMinutes(utcDate.getTimezoneOffset() * 2);
        }
        return utcDate;
    }

    /**
     * To return this date string format: 2017-01-31T00:00:00.000
     * @function module:morningstar.asterix.util.date.toUTCLocalISOString
     * @param {Object} date
     * @return {String}
     */
    function toUTCLocalISOString(date) {
        return toLocalISOString(convertToUTC(date));
    }

    return {
        addMonths: addMonths,
        extractDateFromDatetime: extractDateFromDatetime,
        monthDiff: monthDiff,
        getMonthEndDate: getMonthEndDate,
        convertToUTC: convertToUTC,
        toLocalISOString: toLocalISOString,
        toUTCLocalISOString: toUTCLocalISOString,
        convertToStandard: convertToStandard,
        utcToStandard: utcToStandard
    };

});
