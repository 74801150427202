define(['morningstar'], function(morningstar) {
    function ConsoleLogProvider() {

    }
    ConsoleLogProvider.prototype.setLogLevel = function(logLevel) {
        this.logLevel = logLevel;
    };
    //Since the error will be rethrown by logger() so no need to log them in console
    ConsoleLogProvider.prototype.fatal = function() {
    };
    ConsoleLogProvider.prototype.error = function() {
        console.error.apply(console, arguments);
    };
    ConsoleLogProvider.prototype.warn = function() {
        console.warn.apply(console, arguments);
    };
    ConsoleLogProvider.prototype.info = function() {
        console.info.apply(console, arguments);
    };
    ConsoleLogProvider.prototype.debug = function() {
        console.debug.apply(console, arguments);
    };

    //console.trace in IE, Firefox doesn't print arguments, instead they print only the stacktrace
    //which is not the purpose of this trace. This is meant for performance-tracing
    ConsoleLogProvider.prototype.trace = function() {
        console.debug.apply(console, arguments);
    };

    return ConsoleLogProvider;
});
