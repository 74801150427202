define(['./each.js', './is-object', '../extend.js', './is-string.js', '../instrumentation.js', './merge.js', '../util/is-number.js'],
    function(each, isObject, extend, isString, instrumentation, merge, isNumber) {
    'use strict';

    var cache = {};
    var _abbrs = {
        thousands: {
            pow10: 3,
            next: 6
        },
        millions: {
            pow10: 6,
            next: 9
        },
        billions: {
            pow10: 9,
            next: 12
        },
        trillions: {
            pow10: 12,
            next: null
        }
    };

    var _specialCurrencies = {
        GBX: {
            symbol: 'p',
            name: 'pence'
        }
    };

    var defaults = {
        negativeTemplate: '−%v',
        nullString: '–'
    };

    var regexMatchDigits = /([0-9,._' ]*[0-9])([\sa-zA-Z]*)$/;

    return {
        getFormatter: getNumberFormatter
    };

    function _buildKeySegments(segments, options) {
        each(options, function(v, k) {
            segments.push(k);
            if (isObject(v)) {
                _buildKeySegments(segments, v);
            } else {
                segments.push(v);
            }
        });
    }

    function parseFormatString(formatString) {
        var result = {};
        var parts = formatString.split('|');
        each(parts, function(part) {
            var segments = part.split(':');
            if (segments.length !== 2) {
                return;
            }

            segments[0] = segments[0].trim();
            segments[1] = segments[1].trim();

            switch (segments[0]) {
                case 'abbr':
                    result.abbreviate = result.abbreviate || {};
                    result.abbreviate.mode = segments[1];
                    break;
                case 'dec':
                    var fromTo = segments[1].split(',');
                    result.minimumFractionDigits = parseInt(fromTo[0]);
                    result.maximumFractionDigits = parseInt(fromTo[1]);
                    break;
                case 'style':
                case 'currency':
                case 'currencyDisplay':
                case 'prefix':
                case 'suffix':
                    result[segments[0]] = segments[1];
                    break;
                default:
                    instrumentation.logger.fatal(new Error('Could not parse format string containing key: ' + segments[0]));
            }
        });

        return result;
    }

    function getNumberFormatter(culture, options, formatAliases, abbreviations, defaultCurrencyId) {
        options = options || {};
        options = translateAlias(options, formatAliases);

        if (options.formatString) {
            options = merge({}, options, parseFormatString(options.formatString));
        }

        options = merge({}, options);

        options.currency = options.currency || defaultCurrencyId;
        options.abbreviate = options.abbreviate || {};
        options.abbreviate.abbreviations = options.abbreviate.abbreviations || abbreviations;

        var key = [];
        key.push(culture);
        _buildKeySegments(key, options);
        key = key.join('|');

        if (!cache[key]) {
            cache[key] = createFormatter(culture, options, formatAliases);
        }

        return cache[key];
    }

    function translateAlias(alias, formatAliases) {
        if (!isString(alias)) {
            return alias;
        }

        var result = formatAliases[alias];
        if (!result) {
            instrumentation.logger.fatal(new Error('Could not find format alias: ' + alias));
        }

        return result;
    }

    function createFormatter(culture, options, formatAliases) {
        options = merge({}, defaults, options || {});

        if (options.style === 'currency' && _specialCurrencies[options.currency]) {
            options.suffix = options.suffix || '';
            options.currencyDisplay = options.currencyDisplay || 'symbol';

            if (options.currencyDisplay === 'symbol' && _specialCurrencies[options.currency].symbol) {
                options.suffix += _specialCurrencies[options.currency].symbol;
            } else if (options.currencyDisplay === 'name' && _specialCurrencies[options.currency].name) {
                options.suffix += ' ' + _specialCurrencies[options.currency].name;
            } else { // code
                options.suffix += ' ' + options.currency;
            }

            options.style = 'decimal';
        }

        var _intlNumberFormat;
        if (window.Intl) {
            _intlNumberFormat = window.Intl.NumberFormat(options.culture || culture, options);
        } else {
            _intlNumberFormat = {
                format: function (v) {
                    if (isNumber(v)) {
                        return v.toFixed(0);
                    }
                    return v;
                }
            }
        }

        var cache = {};

        return {
            format: function format(value, optionOverrides) {

                if (optionOverrides && (isString(optionOverrides) || Object.keys(optionOverrides).length)) {
                    optionOverrides = translateAlias(optionOverrides, formatAliases);

                    var opts = merge({}, options, optionOverrides);
                    return getNumberFormatter(opts.culture || culture, opts).format(value);
                }

                if (value == null || isNaN(value)) {
                    return options.nullString;
                }

                var cacheKey = value ? value.toString() : 'null';
                if (cache[cacheKey]) {
                    return cache[cacheKey];
                }

                var abbr = null,
                    prefix = options.prefix || '',
                    suffix = options.suffix || '';

                // abbreviate if specified
                if (options.abbreviate && options.abbreviate.mode) {
                    abbr = abbreviate(value, options);
                    value = abbr.value;
                    // prepend abbreviation suffix to the suffix
                    suffix = abbr.abbreviation + suffix;
                }

                if (options.minimumFractionDigits > options.maximumFractionDigits) {
                    options.maximumFractionDigits = options.minimumFractionDigits;
                }

                if (options.minimumSignificantDigits > options.maximumSignificantDigits) {
                    options.maximumSignificantDigits = options.minimumSignificantDigits;
                }

                if (Math.abs(round(value, options.maximumFractionDigits)) === 0) {
                    value = 0;
                }

                var result;
                if (value < 0 && options.negativeTemplate && options.negativeTemplate !== '-%v') {
                    result = _intlNumberFormat.format(Math.abs(value));
                    result = result.replace(regexMatchDigits, function(m, number, rest) {
                        return options.negativeTemplate.replace('%v', number) + suffix + rest;
                    });
                } else {
                    result = _intlNumberFormat.format(value);
                    if (suffix) {
                        result = result.replace(regexMatchDigits, function(m, number, rest) {
                            return number + suffix + rest;
                        });
                    }
                }

                if (prefix) {
                    result = prefix + result;
                }

                cache[cacheKey] = result;
                return result;
            }
        };
    }

    function round(n, i) {
        return +(Math.round(n + 'e+'+i)  + 'e-'+i);
    }

    function abbreviate(value, options) {
        var abs = Math.abs(value),
            abbr = '';

        each(_abbrs, function(v, k) {
            if (abbr) {
                return;
            }

            // match abbr relevant to the value (mode: auto) OR forced by specific mode
            if (_abbrModeMatch(abs, k, options.abbreviate.mode)) {
                abbr = options.abbreviate.abbreviations[k];
                value = value / Math.pow(10, v.pow10);
            }
        });

        return {
            value: value,
            abbreviation: abbr
        };
    }

    function _abbrModeMatch(abs, abbr, mode) {
        var abbrDef = _abbrs[abbr];
        return (abs >= Math.pow(10, abbrDef.pow10) && (abbrDef.next == null || Math.pow(10, abbrDef.next) > abs) &&
            mode === 'auto') || mode === abbr;
    }
});
