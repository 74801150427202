define([], /** @lends morningstar.asterix.util */ function() {
    'use strict';

    /**
     * Determines if a given object is the Window object.
     * @function module:morningstar.asterix.util/isWindow
     * @param  {any}  object of any type
     * @return {Boolean} true if Window, false if not
     */
    var isWindow = function(object) {
        return (object !== null && object === object.window) || (window && object === window);
    };

    return isWindow;
});
