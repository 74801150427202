define(['./map', '../instrumentation.js'], function(map, instrumentation) {
    'use strict';

    var firstUpperCase = function firstUpperCase(str) {
        if (!str) {
            return str;
        }

        return str[0].toUpperCase() + str.slice(1);
    };

    var firstLowerCase = function firstLowerCase(str) {
        if (!str) {
            return str;
        }

        return str[0].toLowerCase() + str.slice(1);
    };

    var separators = ['_', '-', '.', '/'];
    /**
     * Converts a string of (possibly mixed) formats: camelCase, PascalCase, snake_case, dash-case, dot.case, slash/case to a string with the given separator.
     * @param str
     * @param separator
     * @returns {str}
     */
    var separatorCase = function(str, separator, toUpper) {
        if (!str) {
            return str;
        }

        if (separators.indexOf(separator) < 0) {
            instrumentation.logger.fatal(new Error('Tried to call separatorCase with an unknown separator: ' + separator));
        }

        var replaceSeparators = separators.slice();
        replaceSeparators.splice(separators.indexOf(separator), 1);
        replaceSeparators = map(replaceSeparators, function(separator) {
            return separator === '-' ? '\\-' : separator;
        });

        str = firstLowerCase(str);
        var regex = new RegExp(['['].concat(replaceSeparators).concat([']']).join(''), 'g');
        str = str.replace(regex, separator);

        str = str.replace(/[A-Z]/g, function($1) {
            return separator + $1.toLowerCase();
        });

        return toUpper ? str.toUpperCase() : str;
    };

    var snakeCase = function snakeCase(str) {
        return separatorCase(str, '_');
    };

    var dashCase = function dashCase(str) {
        return separatorCase(str, '-');
    };

    var slashCase = function slashCase(str) {
        return separatorCase(str, '/');
    };

    var dotCase = function dotCase(str) {
        return separatorCase(str, '.');
    };

    var camelCase = function camelCase(str) {
        if (str === str.toUpperCase()) {
            str = str.toLowerCase();
        }

        return str.replace(/(?:^\w|[A-Z]|(?:\b|_)\w)/g, function(letter, index) {
            return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
        }).replace(/[\s\-._]+/g, '');
    };

    var format = function format(str) {
        if (str === null) {
            return null;
        }
        var args = Array.prototype.slice.call(arguments, 1);
        return str.replace(/{{(\d+)}}/g, function(match, number) {
            return typeof args[number] != 'undefined'
              ? args[number]
              : match
            ;
        });
    }

    return {
        firstUpperCase: firstUpperCase,
        firstLowerCase: firstLowerCase,
        dashCase: dashCase,
        snakeCase: snakeCase,
        slashCase: slashCase,
        dotCase: dotCase,
        separatorCase: separatorCase,
        camelCase: camelCase,
        format: format
    };
});
