define([], function() {
    'use strict';

    /**
     * Simple clone function.
     * This will remove properties with function values
     * so don't use this for cloning setting objects (unless it's safe)
     */
    var cloneObject = function(obj) {
        return JSON.parse(JSON.stringify(obj));
    };

    return cloneObject;
});
