define([], function() {
    'use strict';

    /**
     * Recursively merges target and source objects using lodash merge function: https://lodash.com/docs/4.17.4#merge
     * Merges arrays recursively. Does not merge inherited properties.
     *
     * Use light-merge instead to merge objects without merging arrays (e.g. for config inheritance where child array should completely overwrite ancestor)
     * and to merge inherited properties.
     */
    var _ = require('../../../vendor/lodash/object.js');
    return _.merge;
});
