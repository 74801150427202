define(['../instrumentation'], function(instrumentation) {
    'use strict';

    return {
        debug: function debug() {
            instrumentation.logger.debug.apply(instrumentation.logger, arguments);
        },
        info: function info() {
            instrumentation.logger.info.apply(instrumentation.logger, arguments);
        },
        error: function error() {
            instrumentation.logger.error.apply(instrumentation.logger, arguments);
        }
    };
});
