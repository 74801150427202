define(['morningstar', '../instrumentation'], function(morningstar, instrumentation) {
    function GoogleGTagUsageTrackingProvider(_gtagWrapper) {
        this._gtagWrapper = _gtagWrapper, self = this;
        instrumentation.registerLogProvider('errorLogProviderForGoogleGTag', {
            logLevel: morningstar.asterix.instrumentation.LOG_TYPE.FATAL | morningstar.asterix.instrumentation.LOG_TYPE.ERROR,
            //https://developers.google.com/analytics/devguides/collection/gtagjs/exceptions
            fatal: function(ex) {
                (self._gtagWrapper || window.gtag)('event', 'exception', {
                    description: ex && ex.message,
                    fatal: true
                });
            },
            error: function(ex) {
                (self._gtagWrapper || window.gtag)('event', 'exception', {
                    description: ex && ex.message,
                    fatal: false
                });
            }
        });
    }

    GoogleGTagUsageTrackingProvider.prototype.onHit = function(hitObj) {
        let label = hitObj.label,
            value = hitObj.value;
        switch (hitObj.type) {
            case 'timing':
                //https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings
                (self._gtagWrapper || window.gtag)('event', 'timing_complete', {
                        'name': hitObj.action,
                        'event_category': hitObj.category,
                        'event_label': hitObj.label || hitObj.componentModulePath,
                        'value': hitObj.value.elapsedTime
                    });
                break;
            case 'event':
                //https://developers.google.com/analytics/devguides/collection/gtagjs/events
                //if value is passed as anything other than numeric or null then GA doesn't track the event
                if(hitObj.value && typeof hitObj.value !== 'number') {
                    label = hitObj.value;
                    value = null;
                }
                (self._gtagWrapper || window.gtag)('event', hitObj.action, {
                        'event_category': hitObj.category,
                        'event_label': label || hitObj.componentModulePath,
                        'value': value
                    });
                break;
            default:
                morningstar.asterix.instrumentation.logger.error(new Error('GoogleGTagUsageTrackingProvider.onHit: Unknown type "' + hitObj.type + '" for hitObj'), hitObj);
        }
    };

    GoogleGTagUsageTrackingProvider.prototype.dispose = function() {
        instrumentation.unregisterLogProvider('errorLogProviderForGoogleGTag');
    };

    return GoogleGTagUsageTrackingProvider;
});
