define(['./util', 'mwc-application-helper'], function(util, mwcApplicationHelper) {

    const defaultKeys = {
        'settings': true,
        'labels': true,
        'format': true,
        'type': true
    };

    function initAsterixMWC() {
        if (!window.mwc) {
            mwcApplicationHelper.default.initializeMwc();
        }

        let getConfigWrapper = function(mwcIdPath, defaultConfig, injectedComponentConfig) {
            defaultConfig = transformConfigFromMwcToAsterix(defaultConfig);

            if (!mwcIdPath) {
                return defaultConfig;
            }

            injectedComponentConfig = transformConfigFromMwcToAsterix(injectedComponentConfig);
            const nonEcConfigParts = {};
            Object.keys(defaultConfig).forEach(key => {
                if(!defaultKeys[key]) {
                    nonEcConfigParts[key] = defaultConfig[key];
                }
            });

            const lastIndex = mwcIdPath.lastIndexOf('.'),
                parentPath = mwcIdPath.substring(0, lastIndex),
                childComponent = mwcIdPath.substring(lastIndex+1);

            let type = defaultConfig.type;
            if (!type) {
                const elem = document.querySelector(`[mwc-id="${mwcIdPath}"]`);
                if (elem) {
                    type = morningstar.asterix.util.string.camelCase(elem.tagName);
                } else if (injectedComponentConfig.type) {
                    type = injectedComponentConfig.type;
                }
            }

            if (!injectedComponentConfig.type) {
                injectedComponentConfig.type = type;
            }

            if (injectedComponentConfig && Object.keys(injectedComponentConfig).length > 1) {
                if (lastIndex === -1) {
                    // Currently doesn't work for top level components that are added dynamically.
                    defaultConfig = util.merge({}, defaultConfig, injectedComponentConfig);
                } else {
                    // updateDefaultConfigForInstance is part of asterix configuration, not MWC. At this point mwc.configuration has been replaced with asterix config.
                    window.mwc.configuration.updateDefaultConfigForInstance(parentPath, childComponent, injectedComponentConfig);
                }
            }

            if (defaultConfig.format) {
                defaultConfig.settings.format = defaultConfig.format;
            }
            window.mwc.configuration.registerMwcComponent(type, defaultConfig, mwcIdPath, parentPath, childComponent);
            const componentConfig = {};
            componentConfig.settings = window.mwc.configuration.getSettings(mwcIdPath);

            const labels = window.mwc.configuration.getLabels(mwcIdPath, componentConfig.settings.languageId);
            componentConfig.labels = {};
            componentConfig.labels[componentConfig.settings.languageId] = labels;
            if (componentConfig.settings.format) {
                componentConfig.format = componentConfig.settings.format;
            }
            return util.merge(componentConfig, nonEcConfigParts);
        };

        function transformConfigFromMwcToAsterix(config) {
            let clonedConfig = util.merge({}, config);

            recurse(clonedConfig);

            function recurse(config) {
                if (config.labels) {
                    config.labels = transformLabelsFromMwcToAsterix(config.labels);
                }

                util.each(config.components, (component) => {
                    recurse(component);
                });
                util.each(config.blueprints, (blueprint) => {
                    recurse(blueprint);
                });
            }

            return clonedConfig;
        }

        function transformLabelsFromAsterixToMwc(labels) {
            let mwcLabels = {};

            util.each(labels, (labelObject, labelKey) => {
                util.each(labelObject, (translation, language) => {
                    if (!mwcLabels[language]) {
                        mwcLabels[language] = {};
                    }

                    mwcLabels[language][labelKey] = translation;
                });
            });

            return mwcLabels;
        }

        function transformLabelsFromMwcToAsterix(labels) {
            let asterixLabels = {};

            util.each(labels, (languageObject, language) => {
                util.each(languageObject, (translation, labelKey) => {
                    if (!asterixLabels[labelKey]) {
                        asterixLabels[labelKey] = {};
                    }

                    asterixLabels[labelKey][language] = translation;
                });
            });

            return asterixLabels;
        }

        window.mwc.asterixConfigWrapper = getConfigWrapper;
        window.mwc._transformLabelsFromAsterixToMwc = transformLabelsFromAsterixToMwc;
        window.mwc._transformLabelsFromMwcToAsterix = transformLabelsFromMwcToAsterix;
        window.mwc._transformConfigFromMwcToAsterix = transformConfigFromMwcToAsterix;
    }

    return {
        initAsterixMWC: initAsterixMWC
    };
});
