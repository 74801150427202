define(['./merge.js', './is-string.js', './each.js', './is-plain-object.js', '../instrumentation.js'], function(merge, isString, each, isPlainObject, instrumentation) {
    'use strict';

    return {
        applyDefinitionsAndAliases: applyFieldDefinitionAndAliases,
        applyDefinitionsAndAliasesToMany: applyMany
    };

    function applyMany(fields, fieldDefinitions, formatAliases) {
        each(fields, function(field, key) {
            fields[key] = applyFieldDefinitionAndAliases(field, fieldDefinitions, formatAliases);
        });

        return fields;
    }

    function applyFieldDefinitionAndAliases(field, fieldDefinitions, formatAliases) {
        function mergeCustomizer(a, b, key) {
            if (key === 'format') {
                if (isPlainObject(b) && Object.keys(b).length < 2) {
                    return;
                }

                var format = b || a;
                if (isString(format)) {
                    format = formatAliases[format];
                }

                return format || {};
            }
        }

        if (!field) {
            return field;
        }

        fieldDefinitions = fieldDefinitions || {};
        formatAliases = formatAliases || {};

        var result = field;
        if (isString(field)) {
            if (!fieldDefinitions[field]) {
                instrumentation.logger.fatal(new Error('Got field by name with missing field definition: ' + field));
            }

            if (field._hasInheritedFromFieldDefinition !== true) {
                result = merge({}, fieldDefinitions[field], mergeCustomizer);
                result.fieldId = field;
                result.fieldName = result.fieldName || field;
                result.format = result.format || {};
                result._hasInheritedFromFieldDefinition = true;
            }
        } else if (field.fieldName) {
            if (field._hasInheritedFromFieldDefinition !== true) {
                result = merge({}, fieldDefinitions[field.fieldName], field, mergeCustomizer);
                result.fieldId = field.fieldId || field.fieldName;
                if (fieldDefinitions[field.fieldName] && fieldDefinitions[field.fieldName].fieldName) {
                    result.fieldName = fieldDefinitions[field.fieldName].fieldName;
                }

                result.format = result.format || {};
                result._hasInheritedFromFieldDefinition = true;
            }
        } else {
            if (field._hasInheritedFromFieldDefinition !== true) {
                result = merge({}, field, function(a, b, key) {
                    if (key === 'format') {
                        var format = b || a;
                        if (isString(format)) {
                            format = formatAliases[format];
                        }

                        return format || {};
                    }
                });
                result.format = result.format || {};
                result._hasInheritedFromFieldDefinition = true;
            }
        }

        // This fixes an obscure bug where a new instance of table isn't created on the demo site
        // so parameters.fields exists but field.format is still a string
        if (isString(result.format)) {
            result.format = formatAliases[result.format] || {};
        }

        return result;
    }
});
