define(['morningstar', '../instrumentation'], function(morningstar, instrumentation) {
    function GoogleAnalyticsUsageTrackingProvider(gaWrapper) {
        this._gaWrapper = gaWrapper, self = this;
        instrumentation.registerLogProvider('errorLogProviderForGoogleAnalytics', {
            logLevel: morningstar.asterix.instrumentation.LOG_TYPE.FATAL | morningstar.asterix.instrumentation.LOG_TYPE.ERROR,
            //https://developers.google.com/analytics/devguides/collection/analyticsjs/exceptions
            fatal: function(ex) {
                (self._gaWrapper || window.ga)('send', 'exception', {
                    exDescription: ex && ex.message,
                    exFatal: true
                });
            },
            error: function(ex) {
                (self._gaWrapper || window.ga)('send', 'exception', {
                    exDescription: ex && ex.message,
                    exFatal: false
                });
            }
        });
    }

    GoogleAnalyticsUsageTrackingProvider.prototype.onHit = function(hitObj) {
        let label = hitObj.label,
            value = hitObj.value;
        switch (hitObj.type) {
            case 'timing':
                //https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings
                (self._gaWrapper || window.ga)('send', hitObj.type, hitObj.category, hitObj.action, hitObj.value.elapsedTime, hitObj.label || hitObj.componentModulePath, hitObj.value.context);
                break;
            case 'event':
                //https://developers.google.com/analytics/devguides/collection/analyticsjs/events
                //if value is passed as anything other than numeric or null then GA doesn't track the event
                if(hitObj.value && typeof hitObj.value !== 'number') {
                    label = hitObj.value;
                    value = null;
                }
                (self._gaWrapper || window.ga)('send', hitObj.type, hitObj.category, hitObj.action, label || hitObj.componentModulePath, value);
                break;
            default:
                morningstar.asterix.instrumentation.logger.error(new Error('GoogleAnalyticsUsageTrackingProvider.onHit: Unknown type "' + hitObj.type + '" for hitObj'), hitObj);
        }
    };

    GoogleAnalyticsUsageTrackingProvider.prototype.dispose = function() {
        instrumentation.unregisterLogProvider('errorLogProviderForGoogleAnalytics');
    };

    return GoogleAnalyticsUsageTrackingProvider;
});
