define(['./namespace.js', './bootstrap.js', './config.js', './extend.js', './util.js', './formatting',
    './validation/index', './instance-registry.js', './config-evaluator.js', './meta.js', './component-init.js',
    './context.js', './component-instance.js', './instrumentation.js', './usage-tracker.js', './ajax', './transforms', './mwc'
], function(namespace, bootstrap, config, extend, util, formatting, validation, instanceRegistry, ConfigEvaluator, meta, componentInit, context, ComponentInstance, instrumentation, usageTracker, ajax, transforms, mwc) {
    'use strict';

    function createAsterix() {
        if (util.isEmptyObject(morningstar.asterix)) {
            mwc.initAsterixMWC();
            morningstar.asterix = {
                register: namespace.register,
                reset: namespace.reset,
                noConflict: namespace.noConflict,
                config: config,
                extend: extend,
                util: util,
                formatting: formatting,
                validation: validation,
                instanceRegistry: instanceRegistry,
                ConfigEvaluator: ConfigEvaluator,
                bootstrap: bootstrap,
                meta: meta,
                context: context,
                ComponentInstance: ComponentInstance,
                instrumentation: instrumentation,
                usageTracker: usageTracker,
                providers: {
                    ConsoleLogProvider: require('./providers/console-log-provider.js'),
                    FilterLogProvider: require('./providers/filter-log-provider.js'),
                    GoogleAnalyticsUsageTrackingProvider: require('./providers/google-analytics-usage-tracking-provider.js'),
                    GoogleGTagUsageTrackingProvider: require('./providers/google-gtag-usage-tracking-provider.js'),
                    NewRelicUsageTrackingProvider: require('./providers/new-relic-usage-tracking-provider.js'),
                    FilterUsageTrackingProvider: require('./providers/filter-usage-tracking-provider.js')
                },
                ajax: ajax,
                transforms: transforms,
            };
        }
    }

    createAsterix();
    morningstar.initComponent = componentInit.init;

    return {
        createAsterix: createAsterix
    };
});
