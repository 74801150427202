define([], function() {
    function ComponentInstanceCollection(instances) {
        this.push.apply(this, instances);
    }

    ComponentInstanceCollection.prototype = new Array();

    ComponentInstanceCollection.prototype.on = function(eventName, callback) {
        var offs = [];
        this.forEach(function(instance, index) {
            offs.push(instance.on(eventName, callback));
        });
        return offs;
    };

    ComponentInstanceCollection.prototype.trigger = function(eventName, args) {
        this.forEach(function(instance, index) {
            instance.trigger(eventName, args);
        });
    };

    ComponentInstanceCollection.prototype.setParameter = function(propertyName, value) {
        this.forEach(function(instance, index) {
            instance.setParameter(propertyName, value);
        });
    };

    ComponentInstanceCollection.prototype.setModelProperty = function(propertyName, value) {
        this.forEach(function(instance, index) {
            instance.setModelProperty(propertyName, value);
        });
    };

    ComponentInstanceCollection.prototype.destroy = function() {
        this.forEach(function(instance, index) {
            instance.destroy();
        });
    };

    ComponentInstanceCollection.prototype.activate = function() {
        this.forEach(function(instance, index) {
            instance.activate();
        });
    };

    ComponentInstanceCollection.prototype.deactivate = function() {
        this.forEach(function(instance, index) {
            instance.deactivate();
        });
    };

    ComponentInstanceCollection.prototype.filterByTypes = function(types) {
        if (morningstar.asterix.util.isArray(types) && types.length) {
            var filteredInstancesCollection = new ComponentInstanceCollection();
            this.forEach(function(instance) {
                if (types.indexOf(instance.getModuleType()) > -1) {
                    filteredInstancesCollection.push(instance);
                }
            });
            return filteredInstancesCollection;
        } else {
            return this;
        }
    };

    return ComponentInstanceCollection;
});
