define(['../validation', '../../util', '../../instrumentation.js'], function(validation, util, instrumentation) {
    'use strict';
    var cache = {};

    function createAndCacheRegex(pattern) {
        if (util.isString(pattern)) {
            if (cache[pattern]) {
                pattern = cache[pattern];
            } else {
                pattern = cache[pattern] = new RegExp(pattern);
            }
        } else {
            instrumentation.logger.fatal(new Error('invalid regex pattern'));
        }

        return pattern;
    }

    validation.addCustomValidationRule('regex', function(val, options) {
        if (!options.pattern) {
            instrumentation.logger.fatal(new Error('regex validator requires a pattern'));
        }

        var regex = options.pattern;
        if (!util.isRegex(regex)) {
            regex = createAndCacheRegex(regex);
        }

        return regex.test(val);
    });
});
