define(['./config.js', './util.js', './formatting.js', './validation/index.js', './extend.js', './config-evaluator.js',
        './validation-state.js', './component-instance-collection.js', './instrumentation', './usage-tracker'
    ],
    function(config, util, formatting, validation, extend, ConfigEvaluator, ValidationState, ComponentInstanceCollection, instrumentation, usageTracker) {
        'use strict';

        /**
         * An instance of ComponentInstance is added to the instance registry for each component.
         * This wraps calls to the config to get settings, labels etc so we don't call the config module directly.
         */

        var eventTypes = [
            'activated',
            'attributeChanged',
            'attributesChanged',
            'cancel',
            'click',
            'dataLoaded',
            'deactivated',
            'destroyed',
            'editItem',
            'labelChanged',
            'labelsChanged',
            'modelChanged',
            'modelPropertyChanged',
            'modelPropertyReset',
            'modelReset',
            'parameterChanged',
            'parametersChanged',
            'save',
            'takeAction',
            'valueChanged',
            'validationReferenceChanged',
            'validationStateChanged',
            'dragStart',
            'drag',
            'dragEnd',
            'dropEnter',
            'drop',
            'dropOut',
            'loaded'
        ];

        // Special Events are handled as regular events even if isActive == false
        var specialEvents = /^(activated|deactivated|destroyed)/;

        var lockedAttributes = ['id', 'mwc-id'];

        var ignoreElementEvents = [
            'attributeChanged',
            'attributesChanged',
            'parameterChanged',
            'parametersChanged'
        ];

        function ComponentInstance(modulePath, instanceRegistry) {
            this._logger = instrumentation.getTagsDecoratedLogger({
                componentModulePath: modulePath
            });
            this._logger.debug('ComponentInstance.ctor()', instanceRegistry);

            this._usageTracker = usageTracker.getComponentDecoratedTracker(this);
            this._tracer = instrumentation.getTagsDecoratedTracer({
                componentModulePath: modulePath
            }, this._logger);

            this.isActive = false;
            this.modulePath = modulePath;
            this.instanceRegistry = instanceRegistry;
            this._moduleType = null;
            this._moduleTypePath = null;

            this.events = {};
            this.listeners = [];
            this.mwcListeners = {};

            this.model = {};
            this.parameters = {};

            this.labelsCache = {};

            this.isDestroyed = false;

            this.eventPathPrevValue = {
                attributeChanged: {},
                parameterChanged: {},
                modelPropertyChanged: {},
                modelPropertyChanging: {},
                modelPropertyReset: {}
            };

            this.unregisterFunctions = [];

            this.resetValidationState();

            var self = this;
            util.each(eventTypes, function(type) {
                self.events[type] = [];
            });
        }

        ComponentInstance.prototype.hasParent = function hasParent() {
            return this.getModulePath().indexOf('.') >= 0;
        };

        ComponentInstance.prototype.getOwnInstanceId = function getOwnInstanceId() {
            var split = this.getModulePath().split('.');
            return split[split.length - 1];
        };

        ComponentInstance.prototype.restoreInstance = function restoreInstance() {
            let elementRef = this.getElementReference();
            if (elementRef) {
                util.each(this.mwcListeners, (listener, eventName) => {
                    elementRef.removeEventListener(eventName, listener);
                });
            }
            this.elementReference = null;
            let attributes = util.cloneObject(this.getAttributes());
            util.each(attributes, (value, name) => {
                if (lockedAttributes.indexOf(name) > -1) {
                    delete attributes[name];
                }
            });
            elementRef = this.getElementReference();
            util.each(this.mwcListeners, (listener, eventName) => {
                elementRef.addEventListener(eventName, listener);
            });
            this.setAttributes(attributes, true);
        };

        ComponentInstance.prototype.resetValidationState = function resetValidationState() {
            this._logger.info('ComponentInstance.resetValidationState()');
            this.validationState = this.validationState || new ValidationState(this);
            this.validationState.reset();
        };

        ComponentInstance.prototype.getValidationState = function getValidationState() {
            return this.validationState;
        };

        ComponentInstance.prototype.resetModel = function resetModel() {
            this._logger.info('ComponentInstance.resetModel()');
            this.model = {};
            this.prevModel = null;
            util.each(this.eventPathPrevValue, function(eventCollection, name) {
                util.each(eventCollection, function(eventPathDefinition) {
                    delete eventPathDefinition.prevValue;
                });
            });

            this.resetValidationState();
            this.trigger('modelReset');
        };

        ComponentInstance.prototype.destroy = function destroy() {
            this._logger.info('ComponentInstance.destroy()');
            var self = this;

            this.trigger('destroyed');
            this.events = {};
            util.each(this.listeners, function(listener) {
                if (!listener.instance.isDestroyed && listener.instance != self) {
                    listener.instance.off(listener.eventName, listener.callback);
                }
            });

            util.each(this.unregisterFunctions, function(f) {
                f();
            });

            this.listeners.length = 0;
            this.isDestroyed = true;
        };

        /**
         * Adds a listener for eventName.
         * @param eventName
         * @param callback
         * @returns {Function} - Returns a function that can be called with no arguments to unregister the listener.
         */
        ComponentInstance.prototype.on = function on(eventName, callback) {
            this._logger.debug('ComponentInstance.on()', eventName);
            const self = this;
            let split,
                mwcListener,
                mwcEventName;

            if ((split = eventName.split(':')).length === 2 && this.eventPathPrevValue[split[0]]) {
                if (!this.eventPathPrevValue[split[0]][split[1]]) {
                    this.eventPathPrevValue[split[0]][split[1]] = {
                        prevValue: null,
                        getValue: util.parse(split[1])
                    };
                }

                if (!this.events.hasOwnProperty(eventName)) {
                    this.events[eventName] = [];
                }
            }

            // if mwc component, add listener to DOM element for 'eventName'
            if (this.getModuleBaseType() === 'mwc' && ignoreElementEvents.indexOf(eventName) === -1) {
                if (!this.mwcListeners[eventName]) {
                    const element = this.getElementReference();
                    const self = this;
                    mwcEventName = eventName;
                    mwcListener = function mwcListener(event) {
                        self.trigger(eventName, event)
                    };
                    if (element) {
                        // we need to manually activate mwc components
                        // or listeners won't be triggered
                        this.activate();
                        element.addEventListener(eventName, mwcListener);
                    }
                    this.mwcListeners[eventName] = mwcListener;
                } else {
                    mwcListener = this.mwcListeners[eventName];
                }
            }

            // if event is not a standard asterix event
            if (!this.events.hasOwnProperty(eventName)) {
                var allowedEvents = morningstar.asterix.meta.get(this.getModuleType(), 'allowedEvents');
                if (mwcEventName) {
                    allowedEvents = allowedEvents || [];
                    allowedEvents.push(mwcEventName);
                }
                // if event is defined as allowed-event in component settings
                if (allowedEvents && allowedEvents.indexOf(eventName) !== -1) {
                    this.events[eventName] = [];
                } else {
                    this._logger.fatal(new Error('(' + this.modulePath + ') Unrecognized event: ' + eventName));
                }
            }

            this.events[eventName].push(callback);
            return function unregister() {
                self.off(eventName, callback, mwcListener);
            };
        };

        ComponentInstance.prototype.off = function off(eventName, callback, mwcListener) {
            this._logger.debug('ComponentInstance.off()', eventName);
            if (!this.events.hasOwnProperty(eventName)) {
                // already off'd
                return;
            }

            if (this.events.hasOwnProperty(eventName) && this.events[eventName].indexOf(callback) >= 0) {
                this.events[eventName].splice(this.events[eventName].indexOf(callback), 1);
            }

            if (mwcListener && this.events[eventName].length === 0 && this.getElementReference()) {
                this.getElementReference().removeEventListener(eventName, mwcListener);
                delete this.mwcListeners[eventName];
            }
        };

        ComponentInstance.prototype.trigger = function trigger(eventName, args) {
            this._logger.info('ComponentInstance.trigger()', eventName, args);
            var self = this;
            var orCb = {
                or: function(callback) {
                    if (!self.events[eventName] || self.events[eventName].length === 0 || !self.isActive) {
                        callback();
                    }
                }
            };

            // Special Events are handled as regular events even if isActive == false
            var isSpecialEvent = specialEvents.test(eventName);

            if (util.isUndefined(args)) {
                args = [];
            }

            if (!this.events[eventName] && !isSpecialEvent) {
                return orCb;
            }

            if (self.isActive || isSpecialEvent) {
                util.each(this.events[eventName], function(callback) {
                    if (callback && util.isFunction(callback)) {
                        callback.apply(self, args);
                    }
                });
            }

            return orCb;
        };

        ComponentInstance.prototype.hasListener = function hasListener(eventName) {
            return this.events.hasOwnProperty(eventName) && this.events[eventName].length > 0;
        };

        ComponentInstance.prototype.listen = function listen(instanceToListenTo, eventName, callback) {
            this._logger.debug('ComponentInstance.listen()', instanceToListenTo, eventName);
            var listener = util.any(this.listeners, function(obj) {
                return obj.instance === instanceToListenTo && obj.eventName === eventName;
            });
            if (!listener) {
                this.listeners.push({
                    instance: instanceToListenTo,
                    eventName: eventName,
                    callback: callback
                });
                instanceToListenTo.on(eventName, callback);
            }
        };

        ComponentInstance.prototype.getModulePath = function getModulePath() {
            return this.modulePath;
        };

        ComponentInstance.prototype.getModuleType = function getModuleType() {
            return this._moduleType = this._moduleType || config.getComponentType(this.getModulePath());
        };

        ComponentInstance.prototype.getModuleBaseType = function getModuleBaseType() {
            if (!this.moduleBaseType) {
                const moduleType = util.string.dashCase(this.getModuleType());
                this.moduleBaseType = moduleType.indexOf('ec-') === 0 ? 'ec' : 'mwc';
            }
            return this.moduleBaseType;
        };

        ComponentInstance.prototype.getModuleTypePath = function getModuleTypePath() {
            var self = this;
            return this._moduleTypePath = this._moduleTypePath || function() {
                var moduleTypePath = '';
                if (self.hasParent()) {
                    moduleTypePath = self.instanceRegistry.get(util.componentPath.resolve(self.getModulePath(), '_')).getModuleTypePath();
                }
                return moduleTypePath === '' ? self.getModuleType() : moduleTypePath + '.' + self.getModuleType();
            }();
        };

        ComponentInstance.prototype.getElementReference = function getElementReference() {
            if (!this.elementReference) {
                this.elementReference = document.querySelector(`[mwc-id="${this.getModulePath()}"]`);
            }
            return this.elementReference;
        };

        ComponentInstance.prototype.getValue = function getValue() {
            return this.value;
        };

        ComponentInstance.prototype.setValue = function setValue(value) {
            this._logger.info('ComponentInstance.setValue()', value);
            this.value = value;
            this.trigger('valueChanged', [value]);
        };

        ComponentInstance.prototype.getModel = function getModel() {
            return this.model;
        };

        ComponentInstance.prototype.setModel = function setModel(model) {
            this._logger.info('ComponentInstance.setModel()', model, this.prevModel);
            if (util.isEqual(model, this.prevModel)) {
                return;
            }

            this.prevModel = util.isObject(model) ? util.merge(util.isArray(model) ? [] : {}, model) : model;
            this._triggerPathEvents('modelPropertyChanging', model);
            this.model = model;
            this.trigger('modelChanged', [model]);
            this._triggerPathEvents('modelPropertyChanged', model);
        };

        ComponentInstance.prototype.setModelProperty = function setModelProperty(propertyName, value) {
            this._logger.info('ComponentInstance.setModelProperty()', propertyName, value);
            var func = util.parse(propertyName);

            if (value !== null && util.isEqual(func(this.prevModel), value)) {
                return;
            }

            this.prevModel = this.prevModel || {};
            func.assign(this.prevModel, util.isObject(value) ? util.merge(util.isArray(value) ? [] : {}, value) : value);
            func.assign(this.model, value);
            this._triggerPathEvents('modelPropertyChanging', this.model, propertyName);

            this.trigger('modelChanged', [this.model]);
            this.trigger('modelPropertyChanged', [value, propertyName]);
            this._triggerPathEvents('modelPropertyChanged', this.model, propertyName);

            if (value === null) {
                this.trigger('modelPropertyReset', [value, propertyName]);
                this._triggerPathEvents('modelPropertyReset', this.model, propertyName);
            }
        };

        ComponentInstance.prototype.getModelProperty = function getModelProperty(propertyName) {
            return util.parse(propertyName)(this.model);
        };

        /**
         * Used for field-level validation (not model validation)
         * @param value
         * @returns {bool}
         */
        ComponentInstance.prototype.validate = function validate(value) {
            this.validator = validation.getValidator(this._getEvaluatedValidationRulesAndListenForChanges());
            if (!this.validator) {
                return;
            }

            var validationState = this.validator.validate(value);
            this._logger.debug('ComponentInstance.validate()', validationState);
            return validationState;
        };

        ComponentInstance.prototype._isValid = function _isValid(validationState) {
            return !util.any(validationState, function(v) {
                return !v.returnValue;
            });
        };

        ComponentInstance.prototype._pathHasBeginning = function _pathHasBeginning(beginning, path) {
            if (beginning && beginning.indexOf('.') !== -1) {
                beginning = beginning.substring(0, beginning.indexOf('.'));
            }

            var firstToken = path.split('.')[0];
            if (firstToken.indexOf('[') > -1) {
                firstToken = firstToken.replace(/\[[0-9]+\]/g, '');
            }

            return firstToken === beginning || !beginning;
        };

        ComponentInstance.prototype._triggerPathEvents = function _triggerPathEvents(eventTypeName, fullObj, matchPrefix) {
            this._logger.info('ComponentInstance.triggerPathEvents()', eventTypeName, fullObj, matchPrefix);
            var self = this;

            util.each(this.eventPathPrevValue[eventTypeName], function(v, path) {
                if (!self._pathHasBeginning(matchPrefix, path)) {
                    return;
                }

                var newValue = v.getValue(fullObj);
                var prevValue = null;

                if (util.isPlainObject(v.prevValue) || util.isArray(v.prevValue)) {
                    //prevValue = v.prevValue;
                    prevValue = util.merge(util.isArray(v.prevValue) ? [] : {}, v.prevValue);
                } else {
                    prevValue = v.prevValue;
                }

                if (!util.isEqual(newValue, v.prevValue)) {
                    if (util.isPlainObject(newValue) || util.isArray(newValue)) {
                        v.prevValue = util.merge(util.isArray(newValue) ? [] : {}, newValue);
                    } else {
                        v.prevValue = newValue;
                    }

                    self.trigger(eventTypeName + ':' + path, [newValue, prevValue]);
                }
            });
        };

        ComponentInstance.prototype._getEvaluatedValidationRulesAndListenForChanges = function _getEvaluatedValidationRulesAndListenForChanges() {
            var self = this;
            var validation = extend(true, {}, this.getSettings()._validation);
            var evaluator = new ConfigEvaluator(this.modulePath, self.instanceRegistry);
            var result = evaluator.evaluate(validation);
            util.each(result.references, function(reference) {
                self.listen(reference.instance, reference.eventType, function(val) {
                    self.trigger('validationReferenceChanged', [reference, val]);
                });
            });

            return result.evaluated;
        };

        ComponentInstance.prototype.getSettings = function getSettings() {
            if (this._cachedSettings) {
                return this._cachedSettings;
            }

            this._cachedSettings = config.getSettings(this.modulePath);
            if (this._cachedSettings.fields) {
                // deep clone the settings, to be able to modify them
                this._cachedSettings = util.merge({}, this._cachedSettings);
                this._cachedSettings.fields = util.field.applyDefinitionsAndAliasesToMany(this._cachedSettings.fields, this._cachedSettings.fieldDefinitions, this._cachedSettings.formatAliases);

                if (this.getSettings().freezeConfig) {
                    util.freeze.deep(this._cachedSettings);
                }
            }

            return this._cachedSettings;
        };

        ComponentInstance.prototype.getLabels = function getLabels() {
            // if we've already evaluated and cached labels for this languageId, return the cached version
            if (this.labelsCache[this.getSettings().languageId]) {
                return this.labelsCache[this.getSettings().languageId];
            }

            return this.labelsCache[this.getSettings().languageId] = this._getEvaluatedLabelsAndListenForChanges();
        };

        ComponentInstance.prototype._getEvaluatedLabelsAndListenForChanges = function _getEvaluatedLabelsAndListenForChanges() {
            var self = this;
            var labels = config.getLabels(this.modulePath, this.getSettings().languageId);
            var evaluator = new ConfigEvaluator(this.modulePath, this.instanceRegistry);
            var hasMustache = false;
            for (var key in labels) {
                if (hasMustache) {
                    break;
                }

                var label = labels[key];
                if (evaluator._hasMustache(label)) {
                    hasMustache = true;
                }
            }

            if (!hasMustache) {
                return labels;
            }

            var result = evaluator.evaluate(labels, {
                model: true,
                value: true,
                settings: true,
                parameters: true
            });
            if (!this.isListeningToLabelReferences) {
                this.isListeningToLabelReferences = true;
                util.each(result.references, function(reference) {
                    self.unregisterFunctions.push(reference.listen(function() {
                        var cached = self.labelsCache[self.getSettings().languageId];
                        var nodeRes = reference.evaluate();
                        util.parse(reference.source.path).assign(cached, nodeRes.evaluated);
                        self.trigger('labelChanged', [nodeRes.evaluated, reference.source.path]);
                    }));
                });
            }

            return result.evaluated;
        };

        ComponentInstance.prototype.getFormatter = function getFormatter() {
            var format, labels, currencyId, formatAliases;

            format = config.getSetting(this.modulePath, 'format');
            formatAliases = config.getSetting(this.modulePath, 'formatAliases');
            labels = config.getLabels(this.modulePath, this.getSettings().languageId);
            currencyId = config.getSetting(this.modulePath, 'currencyId');

            var abbreviations = {
                thousands: labels.thousands,
                millions: labels.millions,
                billions: labels.billions,
                trillions: labels.trillions
            };

            // todo(slind): could add language-dependent nullString

            return formatting.getFormatter(this.getSettings().languageId, format, formatAliases, abbreviations, currencyId);
        };

        ComponentInstance.prototype._cloneParameters = function _cloneParameters(parameters) {
            return util.isObject(parameters) ?
                util.merge(util.isArray(parameters) ? [] : {}, parameters, function mergeCustomizer(a, b, key) {
                    if (util.isFunction(a) && !b) {
                        return a;
                    }

                    if (util.isFunction(b)) {
                        return b;
                    }
                }) : parameters;
        };

        ComponentInstance.prototype.setAttribute = function setAttribute(propertyName, value, triggerEvents, force) {
            const baseType = this.getModuleBaseType();
            let previousValues;

            if (util.isUndefined(triggerEvents)) {
                triggerEvents = true;
            }

            if (baseType === 'mwc') {
                if (lockedAttributes.indexOf(propertyName) > -1) {
                    this._logger.warn('ComponentInstance.setAttribute()', `Cannot change value for locked attribute: ${propertyName}`);
                    return;
                }

                if (!this.prevAttributes) {
                    this.prevAttributes = util.cloneObject(this.getAttributes());
                }
                previousValues = this.prevAttributes;
            } else {
                previousValues = this.prevParameters;
            }

            this._logger.info('ComponentInstance.setAttribute()', propertyName, value, previousValues);

            const func = util.parse(propertyName);
            if (!force && util.isEqual(func(previousValues), value)) {
                return;
            }

            let prevValue;
            if (baseType === 'mwc') {
                let elem = this.getElementReference();
                if (!elem) {
                    return;
                }

                prevValue = util.parse(propertyName)(this.attributes);
                if (util.isUndefinedOrNull(value)) {
                    elem.removeAttribute(propertyName);
                    delete this.attributes[propertyName];
                } else {
                    elem.setAttribute(propertyName, value);
                    this.attributes[propertyName] = value;
                }
                this.prevAttributes = util.cloneObject(this.attributes);
            } else {
                prevValue = util.parse(propertyName)(this.parameters);
                this.setParameter(propertyName, value);
                func.assign(this.parameters, value);
                this.prevParameters = this._cloneParameters(this.parameters);
            }

            if (triggerEvents) {
                this.trigger('attributeChanged', [value, propertyName]);
                this.trigger('attributeChanged:' + propertyName, [value, prevValue]);
            }
        };

        ComponentInstance.prototype.getAttribute = function getAttribute(attributeName) {
            if (this.getModuleBaseType() === 'mwc') {
                let a = this.getAttributes();
                if (!a) {
                    return undefined;
                }
                return a[attributeName];
            } else {
                return this.getParameter(attributeName);
            }
        };

        ComponentInstance.prototype.setAttributes = function setAttributes(values, force) {
            const baseType = this.getModuleBaseType();
            let previousValues;
            force = force || false;

            if (baseType === 'mwc') {
                if (!this.prevAttributes) {
                    this.prevAttributes = util.cloneObject(this.getAttributes());
                }
                previousValues = this.prevAttributes;
            } else {
                previousValues = this.prevParameters;
            }

            this._logger.info('ComponentInstance.setAttributes()', values, previousValues);

            if (util.isEqual(values, previousValues)) {
                return;
            }

            if (baseType === 'mwc') {
                util.each(values, (value, name) => {
                    this.setAttribute(name, value, false, force);
                });
            } else {
                this.setParameters(values);
                this.parameters = values;
                this.prevParameters = this._cloneParameters(values);
            }

            if (!force) {
                this.trigger('attributesChanged', [values]);
                this._triggerPathEvents('attributeChanged', values);
            }
        };

        ComponentInstance.prototype.getAttributes = function getAttributes() {
            if (this.getModuleBaseType() === 'mwc') {
                const self = this;
                return this.attributes = this.attributes || function() {
                    let elem = self.getElementReference();
                    let attribs = {};
                    if (elem) {
                        let nl = elem.attributes;
                        for (var i = 0; i < nl.length; i++) {
                            attribs[nl[i].name] = nl[i].value;
                        }
                    }
                    return attribs;
                }();
            } else {
                return this.getParameters();
            }
        };

        ComponentInstance.prototype.setParameters = function setParameters(parameters) {
            this._logger.info('ComponentInstance.setParameters()', parameters, this.prevParameters);
            if (util.isEqual(parameters, this.prevParameters)) {
                return;
            }

            this.parameters = parameters;
            this.prevParameters = this._cloneParameters(parameters);

            this.trigger('parametersChanged', [this.parameters]);
            this._triggerPathEvents('parameterChanged', parameters);
        };

        ComponentInstance.prototype.getParameters = function getParameters() {
            return this.parameters;
        };

        ComponentInstance.prototype.setParameter = function setParameter(propertyName, value) {
            this._logger.info('ComponentInstance.setParameter()', propertyName, value, this.prevParameters);
            var func = util.parse(propertyName);
            if (util.isEqual(func(this.prevParameters), value)) {
                return;
            }

            var prevValue = util.parse(propertyName)(this.parameters);
            func.assign(this.parameters, value);
            this.prevParameters = this._cloneParameters(this.parameters);

            this.trigger('parameterChanged', [value, propertyName]);
            this.trigger('parameterChanged:' + propertyName, [value, prevValue]);
        };

        ComponentInstance.prototype.getParameter = function getParameter(propertyName) {
            return util.parse(propertyName)(this.parameters);
        };

        ComponentInstance.prototype.getDefaultColorPalette = function getDefaultColorPalette() {
            return this.getSettings().colors || this.getSettings().colorPalettes[this.getSettings().colorPaletteName];
        };

        ComponentInstance.prototype.activate = function activate() {
            this._logger.info('ComponentInstance.activate()');
            this.isActive = true;
            this.trigger('activated');
        };

        ComponentInstance.prototype.deactivate = function deactivate() {
            this._logger.info('ComponentInstance.deactivate()');
            this.isActive = false;
            this.trigger('deactivated');
        };

        /**
         * Deprecated! Use getRelative instead ("findRelative" is inconsistent with what it actually does...)
         * @param relativePath
         * @returns {*}
         */
        ComponentInstance.prototype.findRelative = function findRelative(relativePath) {
            return this.instanceRegistry.get(this.getModulePath(), relativePath);
        };

        /**
         * Finds all components relative to current component based on pattern
         * @param relativePattern
         * @returns ComponentInstanceCollection
         */
        ComponentInstance.prototype.findAllRelative = function findAllRelative(relativePattern) {
            return new ComponentInstanceCollection(this.instanceRegistry.findAll(this.getModulePath() + '.' + relativePattern));
        };

        ComponentInstance.prototype.getRelative = function getRelative(relativePath) {
            return this.instanceRegistry.get(this.getModulePath(), relativePath);
        };

        ComponentInstance.prototype.onChildAdded = function onChildAdded(regexString, cb) {
            this.instanceRegistry.onInstanceAdded(new RegExp(this.getModulePath() + '\\.?' + regexString), cb);
        };

        ComponentInstance.prototype.getLogger = function getLogger() {
            return this._logger;
        };

        ComponentInstance.prototype.getUsageTracker = function getUsageTracker() {
            return this._usageTracker;
        };

        ComponentInstance.prototype.getTracer = function getTracer() {
            return this._tracer;
        };

        return ComponentInstance;
    });
