define(['./string.js', './map.js', './is-array.js'], function(string, map, isArray) {
    'use strict';
    var css = {
        namespaceSelector: namespaceSelector
    };

    return css;

    /**
     * Creates namespaced variants of the given selector(s) based on the given prefix paths.
     * @param {(string|string[])} prefixPaths - camelCased.subPath or [camelCased.subPath, ..]
     * @param {(string|string[])} [selectors] - Ex
     * @returns {string}
     */
    function namespaceSelector(prefixPaths, selectors) {
        prefixPaths = isArray(prefixPaths) ? prefixPaths : [prefixPaths];
        var prefixes = map(prefixPaths, function(path) {
            return string.dashCase(path);
        });

        if (!selectors) {
            return prefixes.join(' ');
        }

        return map(isArray(selectors) ? selectors : selectors.split(' '), function(selector) {
            selector = string.dashCase(selector);
            return map(prefixes, function(prefix) {
                if (!prefix) {
                    return selector;
                }

                if (selector.indexOf('-') === 0) {
                    return [prefix, selector].join('');
                }

                return [prefix, selector].join('-');
            }).join(' ') + (prefixes.length && prefixes[0] ? ' ' + selector : '');
        }).join(' ');
    }
});
