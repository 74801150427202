define(['axios', './usage-tracker', './instrumentation', './util', './context'], function(axios, usageTracker, instrumentation, util, context) {
    'use strict';

    const isJwtEndpoint = (url, baseUrlUs) => {
        const endpoints = [
            '/api',
            '/ec/api',
            '/ec',
            '/portfolioanalysis'
        ];
        let isJwt = false;
        endpoints.forEach((endpoint) => {
            if (url.indexOf(`${baseUrlUs}${endpoint}`) > -1) {
                isJwt = true;
            }
        });
        return isJwt;
    };

    function handleHttpPromise(result) {
        return result.then(function(response) {
            return response.data;
        }, function(response) {
            if (response) {
                instrumentation.logger.error('(handleHttpPromise) Error:', response);
            }
            response.error = true;
            response.message = null;

            return response;
        });
    }

    function api(apiSettings, data, urlParameters, decoratedLogger, decoratedTracer, decoratedUsageTracker, topLevelModuleType) {
        if (!apiSettings.method || !apiSettings.url) {
            (decoratedLogger || instrumentation.logger).fatal(new Error('apiSettings requires method and url'));
        }

        var startTime = new Date().getTime();
        var trace = (decoratedTracer || instrumentation.tracer).startTrace('mstarAjax.api()');
        var url = urlParameters || (data && apiSettings.method.toLowerCase() === 'get') ?
            util.url.parameterize(apiSettings.url, urlParameters || data) :
            apiSettings.url;

        const env = morningstar.asterix.config.getOption('env'),
            baseUrlUs = (morningstar.asterix.config.getBaseSettings().__env[env] || {}).baseUrlApiGatewayUS;
        if (topLevelModuleType && env === 'qa' && url.indexOf(baseUrlUs + '/ec/v1') > -1) {
            apiSettings.headers = apiSettings.headers || {};
            apiSettings.headers['X-API-ToolID'] = topLevelModuleType;
        }

        if (url.indexOf(baseUrlUs) > -1 && context.getToken('jwtToken')) {
            apiSettings.headers = apiSettings.headers || {};
            apiSettings.headers.authorization = context.getToken('jwtToken');
            if (isJwtEndpoint(url, baseUrlUs)) {
                url = url.replace('/v1/', '/v2/');
            }
        }

        const canceller = axios.CancelToken.source();
        apiSettings = util.merge({}, apiSettings, {
            url: url,
            data: apiSettings.data || data,
            cancelToken: canceller.token
        });

        if (apiSettings.method.toLowerCase() === 'get') {
            apiSettings.data = null;
        }

        addDefaultHeaders(apiSettings);

        var promise = handleHttpPromise(axios(apiSettings));

        promise.then(function() {
            var context = {
                apiSettings: apiSettings,
                data: data,
                urlParameters: urlParameters,
                status: 'success'
            };
            trace.end(context);
            var currentTime = new Date().getTime();
            (decoratedUsageTracker || usageTracker).trackHit({
                type: 'timing',
                action: 'ajax.api',
                label: url,
                value: {
                    startTime: startTime,
                    endTime: currentTime,
                    elapsedTime: currentTime - startTime,
                    context: context
                }
            });
        }, function(response) {
            var context = {
                apiSettings: apiSettings,
                data: data,
                urlParameters: urlParameters,
                response: response,
                status: 'error'
            };
            trace.end(context);
            var currentTime = new Date().getTime();
            (decoratedUsageTracker || usageTracker).trackHit({
                type: 'timing',
                action: 'ajax.api',
                label: url,
                value: {
                    startTime: startTime,
                    endTime: currentTime,
                    elapsedTime: currentTime - startTime,
                    context: context
                }
            });
        });

        return {
            promise: promise,
            cancel: function() {
                var context = {
                    apiSettings: apiSettings,
                    data: data,
                    urlParameters: urlParameters,
                    status: 'cancelled'
                };
                trace.end(context);
                var currentTime = new Date().getTime();
                (decoratedUsageTracker || usageTracker).trackHit({
                    type: 'timing',
                    action: 'ajax.api',
                    label: url,
                    value: {
                        startTime: startTime,
                        endTime: currentTime,
                        elapsedTime: currentTime - startTime,
                        context: context
                    }
                });
                canceller.cancel('Cancelled.');
            }
        };
    }

    function addDefaultHeaders(config) {
        const contentTypeHeader = {
            'Content-Type': 'application/json;charset=utf-8'
        };
        const headers = {
            common: {
                'Accept': 'application/json, text/plain, */*'
            },
            post: util.merge({}, contentTypeHeader),
            put: util.merge({}, contentTypeHeader),
            patch: util.merge({}, contentTypeHeader)
        };

        let reqHeaders = util.merge({}, config.headers);
        let defHeaders = util.merge({}, headers.common, headers[config.method.toLowerCase()]);

        util.each(defHeaders, (defHeader, defHeaderName) => {
            let found = false;
            for (let reqHeaderName in reqHeaders) {
                if (reqHeaderName.toLowerCase() === defHeaderName.toLowerCase()) {
                    found = true;
                }
            }
            if (!found) {
                reqHeaders[defHeaderName] = defHeaders[defHeaderName];
            }
        });

        config.headers = util.merge({}, reqHeaders);
    }

    const requests = {};

    function fetchData(apiSettings) {
        // If we can identify the data source, cancel any previous requests that are still pending
        const dataSourceId = apiSettings.dataSourceId;
        if (dataSourceId) {
            if (requests[dataSourceId] && requests[dataSourceId].cancel) {
                requests[dataSourceId].cancel();
            }
        }

        const request = api(apiSettings);

        if (request && request.promise) {
            if (dataSourceId) {
                requests[dataSourceId] = request;
            }

            return request.promise.then((response) => {
                if (response.error) {
                    if (apiSettings.errorFunction) {
                        apiSettings.errorFunction();
                    }
                } else {
                    if (apiSettings.successFunction) {
                        apiSettings.successFunction();
                    }
                    return response;
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }

    return {
        api,
        fetchData,
        handleHttpPromise
    };
});
