define(['./is-object.js', './is-string.js', './is-array.js', './each.js', './parse.js', '../instrumentation.js'], function(isObject, isString, isArray, each, parse, instrumentation) {
    'use strict';

    function ensureObjectPaths(obj, paths) {
        if (obj == null) {
            return false;
        }

        if (!isObject(obj) ||
            (!isString(paths) && !isArray(paths))) {
            instrumentation.logger.fatal(new Error('Invalid arguments to ensureObjectPaths'));
        }

        paths = isString(paths) ? [paths] : paths;
        var result = true;

        each(paths, function(path) {
            if (!isString(path)) {
                instrumentation.logger.fatal(new Error('Invalid path given to ensureObjectPaths: ' + path));
            }

            if (parse(path)(obj) == null) {
                result = false;
            }
        });

        return result;
    }

    return ensureObjectPaths;
});
