define(['../extend', '../util', '../instrumentation.js'], function(extend, util, instrumentation) {
    'use strict';
    var validators = {},
        baseValidators = {
            'custom': {
                __requires: [{
                    name: 'execute',
                    type: 'function'
                }]
            },
            'regex': {
                __requires: [{
                    name: 'pattern',
                    type: 'regex',
                    transformType: 'string',
                    transform: function(str) {
                        return new RegExp(str);
                    }
                }],
                execute: function(val, options) {
                    return this.pattern.test(val);
                }
            }
        };

    var isValidType = function isValidType(obj, type) {
        switch (type) {
            case 'regex':
                return util.isRegex(obj);
            case 'function':
                return util.isFunction(obj);
            case 'string':
                return util.isString(obj);
        }

        return false;
    };

    var addValidationRule = function addValidationRule(key, validator) {
        if (!validator.type) {
            instrumentation.logger.fatal(new Error('addValidationRule requires type'));
        }

        if (!util.isString(key) && !util.isArray(key)) {
            instrumentation.logger.fatal(new Error('addValidationRule requires a string or array key'));
        }

        if (!baseValidators[validator.type]) {
            instrumentation.logger.fatal(new Error('Unrecognized validator type: ' + validator.type));
        }

        var baseValidator = extend({}, baseValidators[validator.type]);

        util.each(baseValidator.__requires, function(requirement, prop) {
            var value = validator[requirement.name];
            if (!validator.hasOwnProperty(requirement.name) || !isValidType(value, requirement.type)) {
                if (!isValidType(value, requirement.type) && isValidType(value, requirement.transformType)) {
                    validator[requirement.name] = requirement.transform(value);
                } else {
                    instrumentation.logger.fatal(new Error('baseValidator requires property: ' + requirement.name + ' of type ' +
                        requirement.type));
                }
            }
        });

        delete baseValidator.__requires;

        validator = extend(baseValidator, validator);

        if (util.isArray(key)) {
            for (var i in key) {
                var k = key[i];
                if (validators.hasOwnProperty(k)) {
                    continue;
                }
                validators[k] = validator;
            }
        } else {
            if (validators.hasOwnProperty(key)) {
                return;
            }
            validators[key] = validator;
        }
    };

    var addCustomValidationRule = function addCustomValidationRule(key, execute) {
        addValidationRule(key, {
            type: 'custom',
            execute: execute
        });
    };

    var getValidatorForType = function getValidatorForType(type) {
        var validator = validators.hasOwnProperty(type) ? validators[type] : null;
        if (validator === null) {
            instrumentation.logger.fatal(new Error('Invalid validator key: ' + type));
        }

        return validator;
    };

    var shouldRun = function shouldRun(validator, isFalsy) {
        return isFalsy === false || validator.runFalsy === true;
    };

    var validateDataType = function validateDataType(dataType, val) {
        switch (dataType) {
            case 'number':
                return util.isNumber(val);
            case 'string':
                return util.isString(val);
            case 'date':
                return util.isDate(val);
            default:
                instrumentation.logger.fatal(new Error('Unrecognized data type: ' + dataType));
        }
    };

    var getValidator = function getValidator(options) {
        options = options || {};
        if ((!options.rules || !util.isPlainObject(options.rules) || Object.keys(options.rules).length === 0) &&
            !util.isString(options.dataType)) {
            return null;
        }

        var parsed = options.path ? util.parse(options.path) : null;

        return {
            validate: function validate(val) {
                val = parsed ? parsed(val) : val;

                var results = {},
                    isEmpty = val == null || val === '';

                // run data type validation first and return if it fails (don't validate the data type of empty values)
                if (util.isString(options.dataType) && isEmpty === false) {
                    results.dataType = {
                        rule: {
                            type: 'dataType',
                            labelKey: options.dataTypeLabelKey || ('fieldInvalidDatatype' + util.string.firstUpperCase(options.dataType))
                        },
                        returnValue: validateDataType(options.dataType, val)
                    };
                    if (!results.dataType.returnValue) {
                        return results;
                    }
                }

                util.each(options.rules, function(rule, prop) {
                    var validator = getValidatorForType(rule.type);

                    // skip validations that don't validate non-empty values
                    if (!shouldRun(validator, isEmpty)) {
                        return;
                    }

                    if (results[prop]) {
                        instrumentation.logger.fatal(new Error('Multiple occurrences of the same rule key: ' + prop));
                    }

                    results[prop] = {
                        rule: rule,
                        returnValue: validator.execute(val, rule)
                    };
                });

                return results;
            }
        };
    };

    return {
        addValidationRule: addValidationRule,
        addCustomValidationRule: addCustomValidationRule,
        getValidator: getValidator
    };

});
