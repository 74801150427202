define(['morningstar', './config.js', './instance-registry.js', './util.js', './instrumentation'], function(morningstar, config, instanceRegistry, util, instrumentation) {
    'use strict';
    /**
     * Wrapper for calling config.init() and instanceRegistry.init() (added as default callbacks).
     * Allows registering other callback methods, e.g. for bootstrapping angular.
     */

    var _bootstrapCallbacks = [];
    var _hasInited = false;
    var _initTimestamp;

    reset();

    return {
        init: init,
        getInitTimestamp: getInitTimestamp,
        reset: reset,
        registerCallback: registerCallback
    };

    function init(options) {
        var trace = instrumentation.tracer.startTrace('bootstrap.init()');
        _initTimestamp = new Date().getTime();
        if (_hasInited && window && !window.__karma__ && !window.__testing__) {
            if (window.console) {
                instrumentation.logger.warn('bootstrap.init()', 'Tried to call asterix.bootstrap.init multiple times');
            }

            return;
        }

        _hasInited = true;
        options = options || {};
        var appConfig = options.appConfig;
        delete options.appConfig;
        options = util.merge({}, {
            appName: 'morningstarApp',
            appConfig: null,
            baseLabels: {},
            baseSettings: {},
            configOptions: {
                dynamic: false
            }
        }, options || {});

        options.appConfig = appConfig;
        options.defaultConfig = morningstar.components._defaultConfig || {};

        // This is required for SAL to pick languageId. Refer ticket #32079, #33922.
        morningstar.appConfig = morningstar.asterix.extend(true, {}, options.appConfig, morningstar.appConfig);
        // todo(slind): we could merge options.appConfig with morningstar.components._appConfig, but not sure if that's behaviour that we want

        if (!options.appConfig || !options.appConfig.components || Object.keys(options.appConfig.components).length === 0) {
            options.configOptions.dynamic = true;
        }

        if (options.apiTokenExpiredCallback) {
            morningstar.asterix.context.setApiTokenExpiredCallback(options.apiTokenExpiredCallback);
        }

        util.each(_bootstrapCallbacks, function(cb) {
            cb(options);
        });
        trace.end(options);
    }

    function getInitTimestamp() {
        return _initTimestamp;
    }

    function registerCallback(cb) {
        _bootstrapCallbacks.push(cb);
    }

    function reset() {
        instrumentation.logger.debug('bootstrap.reset()');
        _bootstrapCallbacks = [];
        _bootstrapCallbacks.push(function defaultCb(options) {
            config.init(options.appConfig ||
                morningstar.components._appConfig ||
                {components: {}}, options.defaultConfig, options.baseLabels, options.baseSettings, options.configOptions);
            instanceRegistry.init();
            if (window.mwc && window.mwc.asterixConfigWrapper) {
                config.setOption('dynamic', true);
                config.getConfig = window.mwc.asterixConfigWrapper;
                util.merge(window.mwc.configuration, config);
            }
        });
    }
});
