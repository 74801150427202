define([], function() {
    'use strict';
    /**
     * Determines if a variable object is an empty object {}.
     * @function module:morningstar.asterix.util/isEmptyObject
     * @param  {any}  object any type of object
     * @return {Boolean} true if object that is empty, otherwise false
     */
    var isEmptyObject = function(object) {
        var t;
        for (t in object) {
            if (object.hasOwnProperty(t)) {
                return !1;
            }
        }
        return !0;
    };

    return isEmptyObject;
});
