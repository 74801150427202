define(['./util/each.js', './util/is-equal.js', './util/is-string.js', './util/is-function.js', './instrumentation'], function(each, isEqual, isString, isFunction, instrumentation) {
    'use strict';

    /**
     * This handles settings that aren't part of config, e.g. API tokens.
     */

    var tokens, tokenChangedListeners, apiTokenExpiredCallback;

    reset();

    return {
        getToken: getToken,
        getTokens: getTokens,
        getTokenPrependBearer: getTokenPrependBearer,
        setToken: setToken,
        onTokenChanged: onTokenChanged,
        reset: reset,
        getApiTokenExpiredCallback: getApiTokenExpiredCallback,
        setApiTokenExpiredCallback: setApiTokenExpiredCallback
    };

    function getTokens() {
        return tokens;
    }

    function getToken(key) {
        if (!key || !isString(key)) {
            instrumentation.logger.fatal(new Error('getToken expects a string key'));
        }

        return tokens[key];
    }

    function getTokenPrependBearer(key) {
        let token = getToken(key);

        return token.indexOf('Bearer ') === 0 ? token : 'Bearer ' + token;
    }

    function setToken(key, value) {
        instrumentation.logger.info('context.setToken()', key, value);
        if (!key || !isString(key)) {
            instrumentation.logger.fatal(new Error('setToken expects a string key'));
        }

        if (isEqual(tokens[key], value)) {
            return;
        }

        tokens[key] = value;
        each(tokenChangedListeners[key] || [], function iterateTokenChangedListeners(callback) {
            callback(tokens[key]);
        });
    }

    function onTokenChanged(key, callback) {
        instrumentation.logger.debug('context.onTokenChanged()', key);
        if (!key || !isString(key)) {
            instrumentation.logger.fatal(new Error('onTokenChanged expects a string key'));
        }

        if (!isFunction(callback)) {
            instrumentation.logger.fatal(new Error('onTokenChanged expects a callback of type function'));
        }

        if (!tokenChangedListeners[key]) {
            tokenChangedListeners[key] = [];
        }

        tokenChangedListeners[key].push(callback);

        return function off() {
            tokenChangedListeners[key].splice(tokenChangedListeners[key].indexOf(callback), 1);
        };
    }

    function setApiTokenExpiredCallback(callback) {
        apiTokenExpiredCallback = callback;
    }

    function getApiTokenExpiredCallback() {
        return apiTokenExpiredCallback;
    }

    function reset() {
        instrumentation.logger.info('context.reset()');
        tokens = {};
        tokenChangedListeners = {};
    }
});
