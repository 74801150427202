define(['./provider-collection.js', './instrumentation.js', './util.js', './providers/filter-usage-tracking-provider.js'], function(ProviderCollection, instrumentation, util, FilterUsageTrackingProvider) {
    'use strict';

    var _usageTrackingProviders = new ProviderCollection();

    function ComponentDecoratedTracker(componentInstance) {
        this._componentInstance = componentInstance;
    }

    ComponentDecoratedTracker.prototype.trackHit = function(hitObj) {
        hitObj.sourceComponent = this._componentInstance;
        trackHit(hitObj);
    };

    ComponentDecoratedTracker.prototype.setAppContext = function(appContext) {
        setAppContext(appContext);
    };

    /** hitObj can have following properties
        type: type of hit, default is "event"
        category: category for the hit
        action: what was the action performed. eg: click, change (for textbox, radio, checkbox, dropdown etc), play etc
        label: Label for the hit: eg: "Export"
        value: value incase the action was on an input control. For other events any other useful information to capture
        sourceComponent: Mstar component instance object from where this was called. Based on the source following properties are pre-populated
            componentType: type of component eg: "mstarChildType"
            componentTypePath: path type of component eg: "mstarGrandParentType.mstarParentType.mstarChildType"
            componentModulePath: path for componennt eg: "grandparent1.parent1.child1"
    */
    function trackHit(hitObj) {
        if (_usageTrackingProviders.hasProviders()) {
            if (hitObj.sourceComponent) {
                hitObj.componentType = hitObj.sourceComponent.getModuleType();
                hitObj.componentTypePath = hitObj.sourceComponent.getModuleTypePath();
                hitObj.componentModulePath = hitObj.sourceComponent.getModulePath();
                if (!hitObj.category) {
                    var typesPath = hitObj.componentTypePath.split('.');
                    hitObj.category = typesPath && typesPath.length && typesPath[0];
                }
            }
            _usageTrackingProviders.each(function(provider) {
                provider.onHit(hitObj);
            });
        }
    };

    /** appContext can have following properties
        namespace: namespace of the solution. Eg: "demo.solution"
        environment: environment of the solution. Eg: "prod"
        configVersion: version of the config. Eg: 1
        userIdentity: Identity object of the user. Can have following properties
            userId: Client defined User Id
            sessionId: Client defined Session Id
    */
    function setAppContext(appContext) {
        if (_usageTrackingProviders.hasProviders()) {
            _usageTrackingProviders.each(function(provider) {
                if(typeof provider.setAppContext === 'function') {
                    provider.setAppContext(appContext);
                }
            });
        }
    };

    return {
        trackHit: trackHit,
        setAppContext: setAppContext,
        getComponentDecoratedTracker: function(componentInstance) {
            return new ComponentDecoratedTracker(componentInstance);
        },
        registerUsageTrackingProvider: function(name, provider, filterOptions) {
            if (filterOptions) {
                _usageTrackingProviders.register(name, new FilterUsageTrackingProvider(filterOptions, provider));
            } else {
                _usageTrackingProviders.register(name, provider);
            }
        },
        unregisterUsageTrackingProvider: function(name) {
            var provider = _usageTrackingProviders.get(name);
            if (provider && util.isFunction(provider.dispose)) {
                provider.dispose();
            }
            _usageTrackingProviders.unregister(name);
        },
        getUsageTrackingProvider: function(name) {
            return _usageTrackingProviders.get(name);
        }
    };
});
