define(['./util', './instrumentation', './extend'], function(util, instrumentation, extend) {
    'use strict';

    const logger = (context) => context && context.instance ? context.instance.getLogger() : instrumentation.logger;
    const tracer = (context) => context && context.instance ? context.instance.getTracer() : instrumentation.tracer;

    let transforms = {};

    return {
        transformCloned: transformCloned,
        transform: transform,
        transformAsyncCloned: transformAsyncCloned,
        transformAsync: transformAsync,
        register: registerTransform,
        remove: removeTransform,
        isPipelineEmpty: isPipelineEmpty,
        reset: reset
    };

    function registerTransform(name, transform) {
        logger().info('transform.registerTransform()', name, transform);
        if (transforms.hasOwnProperty(name)) {
            logger().warn(`Tried to add transform with the name ${name} multiple times!`);
            return;
        }

        if (util.isArray(transform)) {
            // todo: add support for async
            transform = _createSyncTransformFunction(transform);
        }

        transforms[name] = transform;
    }

    function _createSyncTransformFunction(pipeline) {
        return function(data) {
            const topOptions = this.options;
            const pipelineCopy = _cloneData(pipeline);
            util.each(pipelineCopy, function(transform, i) {
                if (util.isPlainObject(transform)) {
                    util.each(transform.options, function(option, key) {
                        if (option && option.path) {
                            transform.options[key] = util.parse(option.path)(topOptions);
                        }
                    });
                }
            });

            return transform(pipelineCopy, data, this.context);
        };
    }

    function removeTransform(name) {
        logger().info('transform.removeTransform()', name);
        if (transforms[name]) {
            delete transforms[name];
        }
    }

    function reset() {
        logger().info('transform.reset()');
        transforms = {};
    }

    function transformCloned(transformPipeline, data, context) {
        logger(context).info('transform.transformCloned()', transformPipeline, data, context);
        return transform.apply(this, [transformPipeline, _cloneData(data), context]);
    }

    function transformAsyncCloned(transformPipeline, data, context) {
        logger(context).info('transform.transformAsyncCloned()', transformPipeline, data, context);
        return transformAsync.apply(this, [transformPipeline, _cloneData(data), context]);
    }

    function _cloneData(data) {
        if (util.isPlainObject(data)) {
            return extend(true, {}, data);
        } else if (util.isArray(data)) {
            return extend(true, [], data);
        } else {
            return data;
        }
    }

    function transform(transformPipeline, data, context) {
        const trace = tracer(context).startTrace('transform.transform()');
        const result = new TransformPipelineWorker(_getNormalisedPipeline(transformPipeline), data, context).run();
        trace.end({transformPipeline: transformPipeline, data: data, context: context, result: result});
        return result;
    }

    function transformAsync(transformPipeline, data, context) {
        const trace = tracer(context).startTrace('transform.transformAsync()');
        const promise = new TransformPipelineWorker(_getNormalisedPipeline(transformPipeline), data, context).runAsync();
        promise
            .catch((err) => trace.end({transformPipeline: transformPipeline, data: data, context: context, status: 'error', result: err}))
            .then((transformed) => trace.end({transformPipeline: transformPipeline, data: data, context: context, status: 'success', result: transformed}));
        return promise;
    }

    function isPipelineEmpty(transformPipeline) {
        const normalizedPipeline = _getNormalisedPipeline(transformPipeline);
        return !normalizedPipeline || normalizedPipeline.length === 0;
    }

    function _getNormalisedPipeline(transformPipeline) {
        if (util.isUndefined(transformPipeline) || transformPipeline === null || util.isArray(transformPipeline)) {
            return transformPipeline;
        } else if (util.isString(transformPipeline) || util.isObject(transformPipeline)) {
            return [transformPipeline];
        } else {
            logger().fatal(new Error(`_getNormalisedPipeline: unsupported type for transformPipeline: ${transformPipeline.toString()}`));
        }
    }

    function TransformPipelineWorker(transformPipeline, data, context) {
        let _workerPromise,
            _isAsync = false,
            _pipedResult = data,
            _resolve,
            _reject;

        logger(context).info('TransformPipelineWorker.ctor()', transformPipeline, data, context);

        function run() {
            return _executeNext(transformPipeline, _executeNextSync);
        }

        function runAsync() {
            _isAsync = true;
            return new Promise(function(resolve, reject) {
                _reject = reject;
                _resolve = resolve;
                _workerPromise = {
                    resolve: resolve,
                    reject: reject
                };
                _executeNext(transformPipeline, _executeNextAsync);
            });
        }

        function _terminate() {
            logger(context).debug('transform._terminate', transformPipeline, _pipedResult, data, context);
            if (_isAsync) {
                _workerPromise.resolve(_pipedResult);
            } else {
                return _pipedResult;
            }
        }

        function _executeNext(pendingPipeline, executer) {
            if (!pendingPipeline || !pendingPipeline.length) {
                return _terminate();
            }

            const prepared = _prepareTransform(pendingPipeline[0]);
            logger(context).debug('transform._executeNext', prepared, _pipedResult);
            if (!prepared.transform) {
                return _terminate();
            }
            return executer(pendingPipeline, prepared);
        }

        function _executeNextSync(pendingPipeline, prepared) {
            _pipedResult = prepared.transform.apply(prepared.thisObj, [_pipedResult]);
            return _executeNext(pendingPipeline.slice(1), _executeNextSync);
        }

        function _executeNextAsync(pendingPipeline, prepared) {
            const result = prepared.transform.apply(prepared.thisObj, [_pipedResult]);
            if (result && util.isFunction(result.then)) {
                result.then(function(pipedResult) {
                    _pipedResult = pipedResult;
                    _executeNext(pendingPipeline.slice(1), _executeNextAsync);
                }, function(error) {
                    _workerPromise.reject(error);
                });
            } else {
                _pipedResult = result;
                _executeNext(pendingPipeline.slice(1), _executeNextAsync);
            }
        }

        function _prepareTransform(transformInfo) {
            if (util.isPlainObject(transformInfo)) {
                return {
                    name: transformInfo.name,
                    transform: transforms[transformInfo.name],
                    thisObj: {
                        options: transformInfo.options,
                        context: context,
                        origData: data
                    }
                };
            } else {
                return {
                    name: transformInfo,
                    transform: transforms[transformInfo],
                    thisObj: {
                        context: context,
                        origData: data
                    }
                };
            }
        }

        return {
            run: run,
            runAsync: runAsync
        };
    }
});
