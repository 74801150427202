define(['./util/is-number.js', './util/merge.js', './extend', './util/each.js', './provider-collection.js', './providers/filter-log-provider.js'], function(isNumber, merge, extend, each, ProviderCollection, FilterLogProvider) {
    'use strict';
    var _noop = function() {};

    var _logTypes = {
        FATAL: 1,
        ERROR: 2,
        WARN: 4,
        INFO: 8,
        DEBUG: 16,
        TRACE: 32
    };

    var _logLevels = {
        NONE: 0,
        ALL: _logTypes.FATAL | _logTypes.ERROR | _logTypes.WARN | _logTypes.INFO | _logTypes.DEBUG | _logTypes.TRACE,
        DEFAULT: _logTypes.FATAL | _logTypes.ERROR
    };

    var _userIdentity = {},
        _options = {
            formatter: _noop
        };

    function Logger(tags) {
        this._tags = tags ? extend({}, tags) : tags;
        if (_userIdentity && _userIdentity.sessionId) {
            this._tags = this._tags || {};
            this._tags.sessionId = _userIdentity.sessionId;
        }
    }
    Logger.prototype.updateTags = function(tags) {
        if (tags) {
            this._tags = extend(this._tags || {}, tags);
        }
    };
    Logger.prototype._log = function(type, typeCode, logArguments) {
        var self = this;
        _typeSpecificLogProviders.providers[type].each(function(provider) {
            provider[type].apply(provider, self._tags ? logArguments.concat(self._tags) : logArguments);
        });
    };
    Logger.prototype.fatal = function(ex) {
        this._log('fatal', _logTypes.FATAL, Array.apply(null, arguments));
        throw ex;
    };
    Logger.prototype.error = function() {
        this._log('error', _logTypes.ERROR, Array.apply(null, arguments));
    };

    Logger.prototype.warn = logWarn;
    function logWarn() {
        this._log('warn', _logTypes.WARN, Array.apply(null, arguments));
    };

    Logger.prototype.info = logInfo;
    function logInfo() {
        this._log('info', _logTypes.INFO, Array.apply(null, arguments));
    };

    Logger.prototype.debug = logDebug;
    function logDebug() {
        this._log('debug', _logTypes.DEBUG, Array.apply(null, arguments));
    };

    Logger.prototype.trace = logTrace;
    function logTrace() {
        this._log('trace', _logTypes.TRACE, Array.apply(null, arguments));
    };

    var _defaultLoggerInstance = new Logger();

    function Tracer(tags, decoratedLoggerInstance) {
        this._tags = tags;
        this._loggerInstance = decoratedLoggerInstance || _defaultLoggerInstance;
    }

    Tracer.prototype.startTrace = startTrace;
    function startTrace(name, tags, loggerInstance) {
        this._loggerInstance.trace('Tracing started for ' + name);
        return new Trace(name, tags || this._tags, loggerInstance || this._loggerInstance);
    };
    function startTraceNoop() {
        return {
            end: _noop
        };
    }

    Tracer.prototype.endTrace = endTrace;
    function endTrace(traceObj, contextData) {
        return traceObj.end(contextData);
    };

    function Trace(name, tags, loggerInstance) {
        this._name = name;
        this._startTime = new Date().getTime();
        this._alive = true;
        this._tags = tags;
        this._loggerInstance = loggerInstance;
    }

    Trace.prototype.end = traceEnd;
    function traceEnd(contextData) {
        if (!this._alive) {
            this._loggerInstance.warn('Tried to end trace "' + this._name + '" which has already ended');
            return;
        }
        var self = this,
            endTime = new Date().getTime();
        this._alive = false;
        var traceData = {
            startTime: self._startTime,
            endTime: endTime,
            elapsedTime: endTime - self._startTime,
            name: self._name,
            context: contextData,
            tags: self._tags
        };
        this._loggerInstance.trace('Tracing ended for ' + this._name, traceData);
        return traceData;
    };

    var _typeSpecificLogProviders = {
        providers: {},
        register: function(name, provider) {
            var self = this;
            each(_logTypes, function(typeCode, typeName) {
                if (
                    (!isNumber(provider.logLevel) && (typeCode & _logLevels.DEFAULT) === typeCode) || (typeCode & provider.logLevel) === typeCode
                ) {
                    self.providers[typeName.toLowerCase()].register(name, provider);
                }
            });
        },
        get: function(name) {
            var provider, self = this;
            for (var type in _logTypes) {
                if (_logTypes.hasOwnProperty(type)) {
                    provider = self.providers[type.toLowerCase()].get(name);
                    if (provider) {
                        break;
                    }
                }
            }
            return provider;
        },
        unregister: function(name) {
            var self = this;
            each(_logTypes, function(typeCode, typeName) {
                self.providers[typeName.toLowerCase()].unregister(name);
            });
        },
        init: function() {
            var self = this;
            each(_logTypes, function(typeCode, typeName) {
                self.providers[typeName.toLowerCase()] = new ProviderCollection();
            });
        },
        updateLogabilityAndTraceability: function() {
            var self = this,
                haveTraceProviders = self.providers.trace.hasProviders();
            Logger.prototype.warn = self.providers.warn.hasProviders() ? logWarn : _noop;
            Logger.prototype.info = self.providers.info.hasProviders() ? logInfo : _noop;
            Logger.prototype.debug = self.providers.debug.hasProviders() ? logDebug : _noop;
            Logger.prototype.trace = haveTraceProviders ? logTrace : _noop;
            Tracer.prototype.startTrace = haveTraceProviders ? startTrace : startTraceNoop;
            Tracer.prototype.endTrace = haveTraceProviders ? endTrace : _noop;
            Trace.prototype.end = haveTraceProviders ? traceEnd : _noop;
        }
    };

    _typeSpecificLogProviders.init();
    _typeSpecificLogProviders.updateLogabilityAndTraceability();

    return {
        logger: _defaultLoggerInstance,
        getTagsDecoratedLogger: function(tags) {
            return new Logger(tags);
        },
        tracer: new Tracer(),
        getTagsDecoratedTracer: function(tags, decoratedLoggerInstance) {
            return new Tracer(tags, decoratedLoggerInstance);
        },
        LOG_LEVEL: _logLevels,
        LOG_TYPE: _logTypes,
        getLogProvider: function(name) {
            return _typeSpecificLogProviders.get(name);
        },
        registerLogProvider: function(name, provider, filterOptions) {
            _typeSpecificLogProviders.unregister(name);
            if (filterOptions) {
                _typeSpecificLogProviders.register(name, new FilterLogProvider(filterOptions, provider));
            } else {
                _typeSpecificLogProviders.register(name, provider);
            }
            _typeSpecificLogProviders.updateLogabilityAndTraceability();
        },
        unregisterLogProvider: function(name) {
            _typeSpecificLogProviders.unregister(name);
            _typeSpecificLogProviders.updateLogabilityAndTraceability();
        },
        changeLogLevelForProvider: function(providerName, newLogLevel) {
            var provider;
            if (isNumber(newLogLevel)) {
                provider = _typeSpecificLogProviders.get(providerName);
                if (provider && provider.logLevel !== newLogLevel) {
                    provider.logLevel = newLogLevel;
                    _typeSpecificLogProviders.unregister(providerName);
                    _typeSpecificLogProviders.register(providerName, provider);
                }
            }
            _typeSpecificLogProviders.updateLogabilityAndTraceability();
        },
        updateIdentity: function(identity) {
            _userIdentity = merge(_userIdentity || {}, identity);
            if (_userIdentity && _userIdentity.sessionId) {
                _defaultLoggerInstance.updateTags({
                    sessionId: _userIdentity.sessionId
                });
            }
            _defaultLoggerInstance.info(identity);
        },
        getIdentity: function() {
            return _userIdentity;
        },
        resetIdentity: function() {
            _userIdentity = null;
            _defaultLoggerInstance.info('Resetting identity');
        },
        updateOptions: function(options) {
            _options = merge(_options, options);
        }
    };
});
