define(['./config.js', './util.js', './component-instance.js', './component-instance-collection.js', './instrumentation'], function(config, util, ComponentInstance, ComponentInstanceCollection, instrumentation) {
    'use strict';

    var instances = {};
    var instanceAddedListeners = [];

    var instanceRegistry = {
        init: init,
        reset: reset,
        register: register,
        registerExisting: registerExisting,
        get: get,
        findAll: findAll,
        find: find,
        findByType: findByType,
        all: all,
        instances: instances,
        onInstanceAdded: onInstanceAdded
    };

    return instanceRegistry;

    function init(componentPath) {
        var trace = instrumentation.tracer.startTrace('instanceRegistry.init()');
        reset(componentPath);
        var modulePaths = config.getModulePaths();
        util.each(modulePaths, function(val, path) {
            if (!componentPath || path.indexOf(componentPath) === 0){
                var instance = register(path);
                //instance.getSettings();
                //instance.getLabels();
            }
        });
        trace.end(modulePaths);
    }

    function reset(componentPath) {
        instrumentation.logger.info('instanceRegistry.reset()');
        util.each(instances, function(instance, path) {
            if (!componentPath || path.indexOf(componentPath) === 0){
                instance.destroy();
                delete instances[path];
            }
        });
        instanceAddedListeners = [];
    }

    function onInstanceAdded(regex, cb) {
        instanceAddedListeners.push({regex: regex, cb: cb});
    }

    function triggerOnInstanceAddedListeners(instance) {
        util.each(instanceAddedListeners, function(listener) {
            if (listener.regex.test(instance.getModulePath())) {
                listener.cb(instance);
            }
        });
    }

    function register(modulePath, options) {
        if (instances.hasOwnProperty(modulePath)) {
            return instances[modulePath];
        }

        var added = false;
        instrumentation.logger.info('instanceRegistry.register()', modulePath, options);
        if (config.canAddModulePathDynamically(modulePath) && options && options.type && !config.hasInstance(modulePath)) {
            added = true;
            config.addInstance(modulePath, options);
        } else if (!config.hasInstance(modulePath)) {
            instrumentation.logger.fatal(new Error('(instanceRegistry) tried to register a module path that does not exist in the config'));
        }

        var instance = new ComponentInstance(modulePath, instanceRegistry);
        instances[modulePath] = instance;
        if (added) {
            triggerOnInstanceAddedListeners(instance);
        }

        return instance;
    }

    function registerExisting(instance) {
        instances[instance.getModulePath()] = instance;
        return instance;
    }

    function all() {
        return instances;
    }

    /** Find the first instance that matches the given search string. * can be used as "match all".
     * @param search
     */
    function find(search) {
        var regex = _getFindRegex(search);
        for (var k in instances) {
            if (instances.hasOwnProperty(k) && regex.test(k)) {
                return instances[k];
            }
        }
    }

    /** Find all instances that match the given search string. * can be used as "match all".
     * @param {string} search
     */
    function findAll(search) {
        var regex = _getFindRegex(search);
        return new ComponentInstanceCollection(util.filter(instances, function(item, key) {
            return regex.test(key);
        }));
    }

    function findByType(type) {
        return new ComponentInstanceCollection(util.filter(instances, function(instance, key) {
            return instance.getModuleType() === type;
        }));
    }

    function _getFindRegex(search) {
        if (!util.isString(search)) {
            return new RegExp(search);
        } else {
            search = search.replace(/\./g, '\\.')
            .replace(/\*/g, '.*');
            return new RegExp('^' + search + '$');
        }
    }

    function get() {
        if (arguments.length === 2) {
            var relativePath = util.isPlainObject(arguments[1]) ? null : arguments[1];

            if (relativePath != null) {
                var modulePath = util.componentPath.resolve(arguments[0], relativePath);
                if (!instances[modulePath]) {
                    instrumentation.logger.fatal(new Error('(instanceRegistry) could not find any instance for: ' + modulePath));
                }

                return instances[modulePath];
            } else {
                return register(arguments[0], arguments[1]);
            }
        } else if (arguments.length === 1) {
            if (!instances[arguments[0]]) {
                instrumentation.logger.fatal(new Error('(instanceRegistry) could not find any instance for: ' + arguments[0]));
            }

            return instances[arguments[0]];
        } else {
            instrumentation.logger.fatal(new Error('(instanceRegistry) get expects 1 or 2 arguments'));
        }
    }
});
