define(['./extend', './util.js', './instrumentation.js'], function(extend, util, instrumentation) {
    'use strict';

    function getFormatter(languageId, opts, formatAliases, numberAbbreviations, defaultNumberCurrencyId) {
        if (!languageId) {
            instrumentation.logger.fatal(new Error('getFormatter expects languageId'));
        }

        opts = extend(true, {
            number: {
            },
            date: {
            },
            any: {
                number: {
                },
                date: {
                }
            }
        }, opts);

        var numberFormatter = util.intlNumberFormat.getFormatter(languageId, opts.number, formatAliases, numberAbbreviations, defaultNumberCurrencyId).format;
        var anyFormatter = function(value, optionOverrides) {
            var result = value;
            optionOverrides = util.isString(optionOverrides) ? formatAliases[optionOverrides] : optionOverrides;
            optionOverrides = optionOverrides || {};
            var nullString = optionOverrides.optionOverrides || opts.any.nullString;
            if (util.isNumber(value)) {
                result = util.intlNumberFormat.getFormatter(languageId, opts.any.number, formatAliases, numberAbbreviations, defaultNumberCurrencyId).format(value, optionOverrides.number);
            } else if (util.isDate(value)) {
                result = util.intlDateFormat.getFormatter(languageId, opts.any.date, formatAliases).format(value, optionOverrides.date);
            } else {
                result = result || nullString;
            }
            return result;
        };

        var currencySymbol = function(currency, mode) {
            var modes = ['symbol', 'code', 'name'];

            if (modes.indexOf(mode) < 0) {
                mode = modes[0];
            }

            var opts = {
                style: 'currency',
                currency: currency || defaultNumberCurrencyId || 'GBP',
                currencyDisplay: mode,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            };

            return numberFormatter(0, opts).replace('0', '').trim();
        };

        return {
            getOptionValue: function(key) {
                return opts[key];
            },
            number: numberFormatter,
            date: util.intlDateFormat.getFormatter(languageId, opts.date, formatAliases).format,
            currencySymbol: currencySymbol,
            any: anyFormatter
        };
    }

    return {
        getFormatter: getFormatter
    };

});
