define(['morningstar', '../instrumentation'], function(morningstar, instrumentation) {
    function NewRelicUsageTrackingProvider(newRelicApiWrapper) {
        this._newRelicApiWrapper = newRelicApiWrapper;
        var self = this;
        instrumentation.registerLogProvider('errorLogProviderForNewRelic', {
            logLevel: morningstar.asterix.instrumentation.LOG_TYPE.FATAL | morningstar.asterix.instrumentation.LOG_TYPE.ERROR,
            //https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/notice-error
            fatal: function(ex) {
                (self._newRelicApiWrapper || window.newrelic).noticeError(ex && ex.message, ex);
            },
            error: function(ex) {
                (self._newRelicApiWrapper || window.newrelic).noticeError(ex && ex.message, ex);
            }
        });
    }

    NewRelicUsageTrackingProvider.prototype.dispose = function() {
        instrumentation.unregisterLogProvider('errorLogProviderForNewRelic');
    };

    NewRelicUsageTrackingProvider.prototype.onHit = function(hitObj) {
        switch (hitObj.type) {
            case 'timing':
                (this._newRelicApiWrapper || window.newrelic).addToTrace({
                    name: hitObj.action,
                    start: hitObj.value.startTime,
                    end: hitObj.value.endTime,
                    origin: hitObj.componentModulePath,
                    type: hitObj.category
                });
                if (hitObj.action === 'Component loaded') {
                    (this._newRelicApiWrapper || window.newrelic).setCustomAttribute('End time for load component of ' + hitObj.componentType, hitObj.value.elapsedTime);
                }
                (this._newRelicApiWrapper || window.newrelic).addPageAction(hitObj.action, hitObj);
                break;
            case 'event':
                (this._newRelicApiWrapper || window.newrelic).addPageAction(hitObj.action, hitObj);
                break;
            default:
                (this._newRelicApiWrapper || window.newrelic).addPageAction(hitObj.action, hitObj);
        }
    };

    NewRelicUsageTrackingProvider.prototype.setAppContext = function(appContext) {
        let nrInstance = (this._newRelicApiWrapper || window.newrelic);
        //set custom attributes for app
        ['namespace', 'environment', 'configVersion'].forEach((key, index) => {
            nrInstance.setCustomAttribute(key, appContext[key] || '');
        });
        //set custom attributes for userIdentity
        if(appContext.userIdentity) {
            ['userId', 'sessionId'].forEach((key, index) => {
                nrInstance.setCustomAttribute(key, appContext.userIdentity[key] || '');
            });
        }
    };

    return NewRelicUsageTrackingProvider;
});
