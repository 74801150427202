define(['../util/is-string.js'], function(isString) {
    function FilterUsageTrackingProvider(filterOptions, usageTrackingProvider) {
        if (!usageTrackingProvider) {
            throw 'usageTrackingProvider is undefined/null';
        }
        this._componentModulePath = getRegExForString(filterOptions.componentModulePath);
        this._componentType = getRegExForString(filterOptions.componentType);
        this._componentTypePath = getRegExForString(filterOptions.componentTypePath);
        this._type = getRegExForString(filterOptions.type);
        this._category = getRegExForString(filterOptions.category);
        this._action = getRegExForString(filterOptions.action);
        this._filterFunc = filterOptions.filterFunc;
        this._usageTrackingProvider = usageTrackingProvider;
    }

    function getRegExForString(pattern) {
        return isString(pattern) ? new RegExp(pattern) : pattern;
    }

    FilterUsageTrackingProvider.prototype._allow = function(hitObj) {
        return (!this._componentModulePath || (hitObj.componentModulePath && hitObj.componentModulePath.match && hitObj.componentModulePath.match(this._componentModulePath) !== null)) &&
            (!hitObj._componentType || (hitObj.componentType && hitObj.componentType.match && hitObj.componentType.match(this._componentType) !== null)) &&
            (!hitObj._componentTypePath || (hitObj.componentTypePath && hitObj.componentTypePath.match && hitObj.componentTypePath.match(this._componentTypePath) !== null)) &&
            (!this._type || (hitObj.type && hitObj.type.match && hitObj.type.match(this._type) !== null)) &&
            (!this._category || (hitObj.category && hitObj.category.match && hitObj.category.match(this._category) !== null)) &&
            (!this._action || (hitObj.action && hitObj.action.match && hitObj.action.match(this._action) !== null)) &&
            (!this._filterFunc || this._filterFunc.apply(this, args));
    };

    FilterUsageTrackingProvider.prototype.onHit = function(hitObj) {
        if (this._allow(hitObj)) {
            this._usageTrackingProvider.onHit(hitObj);
        }
    };

    FilterUsageTrackingProvider.prototype.setAppContext = function(appContext) {
        if(typeof this._usageTrackingProvider.setAppContext === 'function') {
            this._usageTrackingProvider.setAppContext(appContext);
        }
    };

    return FilterUsageTrackingProvider;
});
