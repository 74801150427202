define([], /** @lends morningstar.asterix.util */ function() {
    'use strict';
    /**
     * Determines if a given object is a function.
     * @function module:morningstar.asterix.util/isFunction
     * @param  {any}  object any type of object
     * @return {Boolean} true if function, false if not
     */
    var isFunction = function(object) {
        return typeof object === 'function';
    };

    return isFunction;
});
