define(['./util/each.js', './instrumentation'], function(each, instrumentation) {
    'use strict';
    var meta = {};

    return {
        register: function register(type, key, value) {
            instrumentation.logger.info('meta.register()', type, key, value);
            if (!meta[type]) {
                meta[type] = {};
            }

            meta[type][key] = value;

            return this;
        },
        get: function get(type, key) {
            if (!key) {
                return meta[type];
            }

            return meta[type] ? meta[type][key] : undefined;
        },
        findTypesWhere: function findTypesWhere(key, value) {
            var results = [];
            each(meta, function(obj, type) {
                if (obj[key] === value) {
                    results.push(type);
                }
            });

            return results;
        }
    };
});
